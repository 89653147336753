<template>
  <section class="mt-6">
    <template v-if="clicouNoPersonalizado">
      <v-card class="my-2 py-4 text-center teal darken-4">
        <h2 class="grey--text text--lighten-4">
          ULTRASSONOGRAFIA OBSTÉTRICA COM DOPPLER E ECOCARDIOGRAMA
        </h2>
      </v-card>
      <v-col
        cols="12"
        class="form-group"
        v-for="index in quantidadeFolhasEditorPersonalizado"
        :key="index"
      >
        <h2>Folha {{ index }}</h2>
        <v-btn plain small @click="removeFolha(index)" class="pl-0">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <vue-editor v-model="conteudoEditorPersonalizado[index - 1]" />
      </v-col>

      <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
    </template>
    <template v-else>
      <v-card>
        <v-card class="my-2 py-4 text-center teal darken-4">
          <h2 class="grey--text text--lighten-4">
            ULTRASSONOGRAFIA OBSTÉTRICA COM DOPPLER E ECOCARDIOGRAMA
          </h2>
        </v-card>
        <v-alert
          v-if="!!laudos[0].folhas"
          outlined
          type="warning"
          border="left"
          text=""
        >
          Esse <strong>laudo</strong> teve a impressão
          <strong>personalizada.</strong>
          <v-btn text @click="adcionarConteudoEditorPersonalizado"
            >Clique aqui para visualizar</v-btn
          >
        </v-alert>

        <v-tabs class="pa-4">
          <v-tab v-for="(laudo, index) in laudos" :key="index">
            Laudo {{ index + 1 }}
          </v-tab>

          <v-tab-item v-for="(laudo, index) in laudos" :key="index">
            <v-row class="pt-5" no-gutters>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >Indicação do exame:</span
                >
                <v-text-field
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeIndicacaoExame"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.indicacaoExame"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeIndicacaoExame"
                  @click="
                    camposExibidos[index].exibeIndicacaoExame = true;
                    laudo.indicacaoExame = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeIndicacaoExame"
                  @click="
                    camposExibidos[index].exibeIndicacaoExame = false;
                    laudo.indicacaoExame = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeGestacao ? '' : 'text--disabled'
                }`"
              >
                <span>Gestação</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="tipoGemeo"
                  :disabled="!camposExibidos[index].exibeGestacao"
                  required
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="infosCompartilhadas.gestacao"
                />
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeGestacao"
                  @click="camposExibidos[index].exibeGestacao = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].exibeGestacao = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Posição fetal</h4>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao2 ? '' : 'text--disabled'
                  }`"
                  >Situação</span
                >
                <v-select
                  :items="['longitudinal', 'transversa']"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.situacao"
                  :disabled="!camposExibidos[index].exibeSituacao2"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSituacao2"
                  @click="
                    camposExibidos[index].exibeSituacao2 = true;
                    laudo.situacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSituacao2 = false;
                    laudo.situacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                  >, apresentação</span
                >

                <v-select
                  :items="['cefálica', 'pélvica', 'córmica']"
                  required
                  :disabled="!camposExibidos[index].exibeSituacao"
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.apresentacao"
                >
                </v-select>

                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                  >com dorso</span
                >

                <v-select
                  :items="['anterior', 'posterior']"
                  :disabled="!camposExibidos[index].exibeSituacao"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.dorso"
                >
                </v-select>

                <span
                  v-if="laudo.dorso2 != ' na linha mediana'"
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                ></span>

                <v-select
                  :items="['à direita', 'à esquerda', 'na linha mediana']"
                  required
                  :disabled="!camposExibidos[index].exibeSituacao"
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.dorso2"
                >
                </v-select>

                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSituacao"
                  @click="
                    camposExibidos[index].exibeSituacao = true;
                    laudo.dorso = undefined;
                    laudo.dorso2 = undefined;
                    laudo.apresentacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSituacao = false;
                    laudo.dorso = undefined;
                    laudo.dorso2 = undefined;
                    laudo.apresentacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Biometria fetal</h4>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exDiamBiparietal
                      ? 'text--disabled'
                      : ''
                  "
                  >DBP :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :disabled="!camposExibidos[index].exDiamBiparietal"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.diametroBiparietal"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exDiamBiparietal
                      ? 'text--disabled'
                      : ''
                  "
                  >mm</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exDiamBiparietal"
                  @click="
                    camposExibidos[index].exDiamBiparietal = true;
                    laudo.diametroBiparietal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exDiamBiparietal"
                  @click="
                    camposExibidos[index].exDiamBiparietal = false;
                    laudo.diametroBiparietal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exPercentil1 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPercentil1"
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.percentil1"
                ></v-text-field>

                <span
                  :class="
                    !camposExibidos[index].exPercentil1 ? 'text--disabled' : ''
                  "
                  >).</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil1"
                  @click="
                    camposExibidos[index].exPercentil1 = true;
                    laudo.percentil1 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil1"
                  @click="
                    camposExibidos[index].exPercentil1 = false;
                    laudo.percentil1 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exCircCranio ? 'text--disabled' : ''
                  "
                  >CC :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exCircCranio"
                  dense
                  @change="getPercentilCC(index)"
                  class="flex-grow-0 mx-2"
                  required
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.circunferenciaCraniana"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exCircCranio ? 'text--disabled' : ''
                  "
                  >mm</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exCircCranio"
                  @click="
                    camposExibidos[index].exCircCranio = true;
                    laudo.circunferenciaCraniana = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exCircCranio"
                  @click="
                    camposExibidos[index].exCircCranio = false;
                    laudo.circunferenciaCraniana = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exPercentil2 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPercentil2"
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.percentil2"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exPercentil2 ? 'text--disabled' : ''
                  "
                  >).</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil2"
                  @click="
                    camposExibidos[index].exPercentil2 = true;
                    laudo.percentil2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil2"
                  @click="
                    camposExibidos[index].exPercentil2 = false;
                    laudo.percentil2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exCircAbdomen ? 'text--disabled' : ''
                  "
                  >CA:</span
                >
                <v-text-field
                  dense
                  :disabled="!camposExibidos[index].exCircAbdomen"
                  class="flex-grow-0 mx-2"
                  @change="getPercentilADBM(index)"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.circunferenciaAbdominal"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exCircAbdomen ? 'text--disabled' : ''
                  "
                  >mm</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exCircAbdomen"
                  @click="
                    camposExibidos[index].exCircAbdomen = true;
                    laudo.circunferenciaAbdominal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exCircAbdomen"
                  @click="
                    camposExibidos[index].exCircAbdomen = false;
                    laudo.circunferenciaAbdominal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exPercentil3 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exPercentil3"
                  dense
                  class="flex-grow-0 mx-2"
                  required
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.percentil3"
                ></v-text-field>

                <span
                  :class="
                    !camposExibidos[index].exPercentil3 ? 'text--disabled' : ''
                  "
                  >).</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil3"
                  @click="
                    camposExibidos[index].exPercentil3 = true;
                    laudo.percentil3 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil3"
                  @click="
                    camposExibidos[index].exPercentil3 = false;
                    laudo.percentil3 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exComprimentoFemoral
                      ? 'text--disabled'
                      : ''
                  "
                  >CF :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exComprimentoFemoral"
                  @change="getPercentilFemur(index)"
                  required
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  v-model="laudo.comprimentoFemoral"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exComprimentoFemoral
                      ? 'text--disabled'
                      : ''
                  "
                  >mm
                </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exComprimentoFemoral"
                  @click="
                    camposExibidos[index].exComprimentoFemoral = true;
                    laudo.comprimentoFemoral = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exComprimentoFemoral"
                  @click="
                    camposExibidos[index].exComprimentoFemoral = false;
                    laudo.comprimentoFemoral = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exPercentil4 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exPercentil4"
                  required
                  v-mask="''"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.percentil4"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exPercentil4 ? 'text--disabled' : ''
                  "
                  >).</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil4"
                  @click="
                    camposExibidos[index].exPercentil4 = true;
                    laudo.percentil4 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil4"
                  @click="
                    camposExibidos[index].exPercentil4 = false;
                    laudo.percentil4 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exPesoFetal ? 'text--disabled' : ''
                  "
                  >Peso fetal estimado de</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPesoFetal"
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  v-model="laudo.pesoFetal"
                  @change="getPercentilPesoFetal(index)"
                ></v-text-field>

                <span
                  :class="
                    !camposExibidos[index].exPesoFetal ? 'text--disabled' : ''
                  "
                  >gramas</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPesoFetal"
                  @click="
                    camposExibidos[index].exPesoFetal = true;
                    laudo.pesoFetal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPesoFetal"
                  @click="
                    camposExibidos[index].exPesoFetal = false;
                    laudo.pesoFetal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exPercentil5 ? 'text--disabled' : ''
                  "
                  >, correspondendo ao percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPercentil5"
                  v-mask="''"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.percentil5"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exPercentil5 ? 'text--disabled' : ''
                  "
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil5"
                  @click="
                    camposExibidos[index].exPercentil5 = true;

                    laudo.percentil5 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil5"
                  @click="
                    camposExibidos[index].exPercentil5 = false;

                    laudo.percentil5 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Vitalidade fetal</h4>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exBatimentoFeto ? 'text--disabled' : ''
                "
              >
                <span>Batimentos cardíacos fetais</span>
                <v-select
                  :items="['presentes', 'ausentes']"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exBatimentoFeto"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.batimentosCardiacosFetais"
                >
                </v-select>

                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'"
                  >,</span
                >
                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'">
                  <v-select
                    :items="['rítmicos', 'com ritmo cardíaco irregular']"
                    :disabled="!camposExibidos[index].exBatimentoFeto"
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.batimentosCardiacosFetais2"
                  >
                  </v-select>
                </span>
                <v-span
                  v-if="
                    laudo.batimentosCardiacosFetais2 ==
                    'com ritmo cardíaco irregular'
                  "
                >
                  <v-textarea
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :disabled="!camposExibidos[index].exBatimentoFeto"
                    required
                    validate-on-blur
                    filled
                    color="#074535"
                    outlined
                    no-resize
                    dense
                    class="flex-grow-0 mx-2"
                    label="Caracterize a arritimia se necessário:"
                    v-model="laudo.caracterizacaoArritimia"
                  >
                  </v-textarea>
                </v-span>

                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'"
                  >, com frequência cardíaca de</span
                >

                <v-span v-if="laudo.batimentosCardiacosFetais == 'presentes'">
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exBatimentoFeto"
                    v-mask="''"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.frequenciabpm"
                  ></v-text-field>
                </v-span>

                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'"
                  >bpm</span
                >
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exBatimentoFeto"
                  @click="
                    camposExibidos[index].exBatimentoFeto = true;
                    laudo.batimentosCardiacosFetais = undefined;
                    laudo.batimentosCardiacosFetais2 = undefined;
                    laudo.caracterizacaoArritimia = undefined;
                    laudo.frequenciabpm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exBatimentoFeto"
                  @click="
                    camposExibidos[index].exBatimentoFeto = false;
                    laudo.batimentosCardiacosFetais = undefined;
                    laudo.batimentosCardiacosFetais2 = undefined;
                    laudo.caracterizacaoArritimia = undefined;
                    laudo.frequenciabpm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exMovCorporais ? 'text--disabled' : ''
                "
              >
                <span>Movimentos corporais</span>
                <v-select
                  :items="['presentes', 'ausentes']"
                  required
                  :disabled="!camposExibidos[index].exMovCorporais"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.movimentosCorporais"
                >
                </v-select>
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exMovCorporais"
                  @click="
                    camposExibidos[index].exMovCorporais = true;
                    laudo.movimentosCorporais = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exMovCorporais"
                  @click="
                    camposExibidos[index].exMovCorporais = false;
                    laudo.movimentosCorporais = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="!camposExibidos[index].exTonus ? 'text--disabled' : ''"
              >
                <span>Tônus</span>
                <v-select
                  :items="['mantido', 'abolido']"
                  dense
                  :disabled="!camposExibidos[index].exTonus"
                  class="flex-grow-0 mx-2"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.tonus"
                >
                </v-select>
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exTonus"
                  @click="
                    camposExibidos[index].exTonus = true;
                    laudo.tonus = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exTonus"
                  @click="
                    camposExibidos[index].exTonus = false;
                    laudo.tonus = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exVolLiquidoAmni
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Volume de líquido amniótico</span>
                <v-select
                  :items="['normal', 'aumentado', 'reduzido', 'ausente']"
                  required
                  dense
                  :disabled="!camposExibidos[index].exVolLiquidoAmni"
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.volumeLíquidoAmniotico"
                >
                </v-select>
                <span>com maior bolsão medindo</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  v-mask="''"
                  :disabled="!camposExibidos[index].exVolLiquidoAmni"
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.percentil6"
                ></v-text-field>
                <span>cm.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exVolLiquidoAmni"
                  @click="
                    camposExibidos[index].exVolLiquidoAmni = true;
                    laudo.volumeLíquidoAmniotico = undefined;
                    laudo.percentil6 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exVolLiquidoAmni"
                  @click="
                    camposExibidos[index].exVolLiquidoAmni = false;
                    laudo.volumeLíquidoAmniotico = undefined;
                    laudo.percentil6 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exPlacenta2 ? 'text--disabled' : ''
                  "
                  >Placenta</span
                >
                <v-select
                  :items="[
                    'anterior',
                    'posterior',
                    'lateral direita',
                    'lateral esquerda',
                    'fúndica',
                  ]"
                  required
                  :disabled="!camposExibidos[index].exPlacenta2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.placenta"
                >
                </v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPlacenta2"
                  @click="
                    camposExibidos[index].exPlacenta2 = true;
                    laudo.placenta = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPlacenta2"
                  @click="
                    camposExibidos[index].exPlacenta2 = false;
                    laudo.placenta = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exInsercao2 ? 'text--disabled' : ''
                  "
                >
                  , de inserção</span
                >
                <v-select
                  :items="[
                    'alta',
                    'baixa margeando o orifício cervical interno',
                    'baixa ultrapassando o orifício cervical interno',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exInsercao2"
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.insercao"
                >
                </v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exInsercao2"
                  @click="
                    camposExibidos[index].exInsercao2 = true;
                    laudo.insercao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exInsercao2"
                  @click="
                    camposExibidos[index].exInsercao2 = false;
                    laudo.insercao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exPlacenta ? 'text--disabled' : ''
                  "
                  >, grau</span
                >
                <v-select
                  :items="['0', 'I', 'II', 'III']"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exPlacenta"
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.grau"
                >
                </v-select>
                <span
                  :class="
                    !camposExibidos[index].exPlacenta ? 'text--disabled' : ''
                  "
                  >segundo a classificação de Grannum.
                </span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPlacenta"
                  @click="
                    camposExibidos[index].exPlacenta = true;

                    laudo.grau = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPlacenta"
                  @click="
                    camposExibidos[index].exPlacenta = false;

                    laudo.grau = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  filled
                  color="#074535"
                  outlined
                  no-resize
                  dense
                  label="Comentários adicionais:"
                  v-model="laudo.comentariosAdcionaisMeio1"
                >
                </v-textarea>
              </v-col>

              <div v-if="laudo.batimentosCardiacosFetais == 'ausentes'">
                <v-col cols="12" class="d-flex justify-start align-center">
                  <h3>Conclusão</h3>

                  <span>Óbito fetal.</span>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exAusentes ? 'text--disabled' : ''
                  "
                >
                  <span>Idade gestacional de</span>
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :disabled="!camposExibidos[index].exAusentes"
                    required
                    v-mask="''"
                    dense
                    class="mx-2 flex-grow-0"
                    validate-on-blur
                    v-model.number="laudo.idadeGestacional"
                  ></v-text-field>
                  <span>semanas.</span>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exAusentes"
                    @click="
                      camposExibidos[index].exAusentes = true;
                      laudo.idadeGestacional = undefined;
                      laudo.comentariosAdcionaisFinal1 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exAusentes"
                    @click="
                      camposExibidos[index].exAusentes = false;
                      laudo.idadeGestacional = undefined;
                      laudo.comentariosAdcionaisFinal1 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exAusentes ? 'text--disabled' : ''
                  "
                >
                  <v-textarea
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exAusentes"
                    validate-on-blur
                    filled
                    outlined
                    no-resize
                    dense
                    label="Comentários adicionais:"
                    v-model="laudo.comentariosAdcionaisFinal1"
                  >
                  </v-textarea>
                </v-col>
              </div>

              <div v-if="laudo.batimentosCardiacosFetais === 'presentes'">
                <v-col cols="12" class="d-flex justify-start align-center">
                  <h3>Conclusão</h3>
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="!camposExibidos[index].exGest ? 'text--disabled' : ''"
                >
                  <span>Gestação tópica</span>
                  <v-select
                    class="mx-2 flex-grow-0"
                    :items="[
                      ...tipoGemeo,
                      {
                        text: 'Quíntuplo',
                        value: 5,
                      },
                    ]"
                    validate-on-blur
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :disabled="!camposExibidos[index].exGest"
                    dense
                    v-model="infosCompartilhadas.gestacao"
                  />

                  <v-select
                    v-if="infosCompartilhadas.gestacao === 2"
                    class="mx-2 flex-grow-0"
                    :items="['dicoriônica', 'monocoriônica']"
                    dense
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    validate-on-blur
                    v-model="laudo.gestacaoTres"
                  />

                  <v-select
                    v-if="infosCompartilhadas.gestacao === 2"
                    class="mx-2 flex-grow-0"
                    :items="['diamniótica ', 'monoamniótica']"
                    dense
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    validate-on-blur
                    v-model="laudo.gestacaoQuatro"
                  />

                  <v-textarea
                    v-if="[3, 4, 5].includes(infosCompartilhadas.gestacao)"
                    class="mx-2 flex-grow-0"
                    dense
                    rows="3"
                    no-resize
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    validate-on-blur
                    v-model="laudo.gestacaoCinco"
                  />
                  <span>.</span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exGest"
                    @click="
                      camposExibidos[index].exGest = true;

                      laudo.gestacaoTres = undefined;
                      laudo.gestacaoQuatro = undefined;
                      laudo.gestacaoCinco = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exGest"
                    @click="
                      camposExibidos[index].exGest = false;

                      laudo.gestacaoTres = undefined;
                      laudo.gestacaoQuatro = undefined;
                      laudo.gestacaoCinco = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col cols="12" class="d-flex justify-start align-center">
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest2
                        ? 'text--disabled'
                        : ''
                    "
                    >Idade gestacional compatível com</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exIdadeGest2"
                    v-mask="'##'"
                    validate-on-blur
                    dense
                    class="flex-grow-0 mx-2"
                    v-model="laudo.idadeGestacional2"
                  ></v-text-field>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exIdadeGest2"
                    @click="
                      camposExibidos[index].exIdadeGest2 = true;

                      laudo.idadeGestacional2 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exIdadeGest2"
                    @click="
                      camposExibidos[index].exIdadeGest2 = false;

                      laudo.idadeGestacional2 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest2
                        ? 'text--disabled'
                        : ''
                    "
                    >semanas
                  </span>
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest ? 'text--disabled' : ''
                    "
                    >&nbsp; e</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exIdadeGest"
                    v-mask="'##'"
                    validate-on-blur
                    dense
                    class="flex-grow-0 mx-2"
                    v-model="laudo.semanas11"
                  ></v-text-field>
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest ? 'text--disabled' : ''
                    "
                    >dias.
                  </span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exIdadeGest"
                    @click="
                      camposExibidos[index].exIdadeGest = true;

                      laudo.semanas11 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exIdadeGest"
                    @click="
                      camposExibidos[index].exIdadeGest = false;

                      laudo.semanas11 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exDataProvavelParto
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <span>
                    Data provável do parto pela biometria fetal atual (40
                    semanas):
                  </span>
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exDataProvavelParto"
                    type="date"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.dataProvavelParto"
                  ></v-text-field>
                  <span>.</span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exDataProvavelParto"
                    @click="
                      camposExibidos[index].exDataProvavelParto = true;

                      laudo.dataProvavelParto = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exDataProvavelParto"
                    @click="
                      camposExibidos[index].exDataProvavelParto = false;

                      laudo.dataProvavelParto = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exDataProvavelPartoPrimTrim
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <span
                    >Data provável do parto corrigida por ultrassonografia do 1º
                    trimestre:</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="
                      !camposExibidos[index].exDataProvavelPartoPrimTrim
                    "
                    type="date"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.dataProvavelParto1Trimestre"
                  ></v-text-field>
                  <span>.</span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exDataProvavelPartoPrimTrim"
                    @click="
                      camposExibidos[index].exDataProvavelPartoPrimTrim = true;

                      laudo.dataProvavelParto1Trimestre = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exDataProvavelPartoPrimTrim"
                    @click="
                      camposExibidos[index].exDataProvavelPartoPrimTrim = false;

                      laudo.dataProvavelParto1Trimestre = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exGestacaoConclusao
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <v-select
                    :items="[
                      'Observamos concordância entre a idade gestacional baseada na DUM, na ultrassonografia do 1º trimestre e na biometria fetal, compatível com crescimento fetal adequado',
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual',
                    ]"
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    :disabled="!camposExibidos[index].exGestacaoConclusao"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.gestacaoConclusao"
                  >
                  </v-select>
                  <span
                    v-if="
                      laudo.gestacaoConclusao ==
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                    "
                    >, sugerindo</span
                  >

                  <span
                    v-if="
                      laudo.gestacaoConclusao ==
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                    "
                  >
                    <v-select
                      :items="['restrição de crescimento fetal', 'macrossomia']"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exGestacaoConclusao"
                      dense
                      class="flex-grow-0 mx-2"
                      validate-on-blur
                      v-model="laudo.gestacaoConclusao2"
                    >
                    </v-select>
                  </span>
                  <span
                    v-if="
                      laudo.gestacaoConclusao ==
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                    "
                  ></span>
                  <span>.</span>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exGestacaoConclusao"
                    @click="
                      camposExibidos[index].exGestacaoConclusao = true;

                      laudo.gestacaoConclusao = undefined;
                      laudo.gestacaoConclusao2 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exGestacaoConclusao"
                    @click="
                      camposExibidos[index].exGestacaoConclusao = false;

                      laudo.gestacaoConclusao = undefined;
                      laudo.gestacaoConclusao2 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exGestacaoConclusao3
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <v-select
                    :items="[
                      'Exame limitado pela posição fetal',
                      'Exame limitado pela quantidade de líquido amniótico',
                      'Exame limitado devido ao aumento de ecogenicidade da parede abdominal materna',
                      '--------------------------------------',
                    ]"
                    required
                    dense
                    class="flex-grow-0 mx-2"
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    :disabled="!camposExibidos[index].exGestacaoConclusao3"
                    validate-on-blur
                    v-model="laudo.gestacaoConclusao3"
                  >
                  </v-select>
                  <span>.</span>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exGestacaoConclusao3"
                    @click="
                      camposExibidos[index].exGestacaoConclusao3 = true;

                      laudo.gestacaoConclusao3 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exGestacaoConclusao3"
                    @click="
                      camposExibidos[index].exGestacaoConclusao3 = false;

                      laudo.gestacaoConclusao3 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col cols="12" class="d-flex justify-start align-center pt-0">
                  <v-textarea
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    validate-on-blur
                    filled
                    outlined
                    no-resize
                    dense
                    label="Comentários adicionais:"
                    v-model="laudo.comentariosAdcionaisFinal2"
                  >
                  </v-textarea>
                </v-col>
              </div>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h3>Artéria Umbilical</h3>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeIndicePulsatilidadeArteriaUmbilical
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Índice de Pulsatilidade:</span>
                <v-text-field
                  v-mask="''"
                  class="mx-2 flex-grow-0"
                  dense
                  @change="getIndicePulsatilidade(index)"
                  required
                  validate-on-blur
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUmbilical
                  "
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.indicePulsatilidadeArteriaUmbilical"
                ></v-text-field>
                <span>- Percentil</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUmbilical
                  "
                  class="mx-2 flex-grow-0"
                  dense
                  v-mask="''"
                  v-model="laudo.percentil18"
                ></v-text-field>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUmbilical
                  "
                  :items="['normal', 'alterado']"
                  v-model="laudo.indiceOpcao3"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-select>
                <span>para idade gestacional.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUmbilical
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUmbilical = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUmbilical = false;
                    laudo.indicePulsatilidadeArteriaUmbilical = undefined;
                    laudo.percentil18 = undefined;
                    laudo.indiceOpcao3 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeDiastole ? '' : 'text--disabled'
                }`"
              >
                <span>Diástole:</span>
                <v-select
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeDiastole"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :items="['presente', 'ausente', 'reversa']"
                  v-model="laudo.diastole"
                ></v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDiastole"
                  @click="
                    camposExibidos[index].exibeDiastole = true;
                    laudo.diastole = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeDiastole = false;
                    laudo.diastole = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h3>Artéria cerebral média</h3>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index]
                    .exibeIndicePulsatilidadeArteriaCerebralMedia
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Índice de Pulsatilidade:</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.indicePulsatilidadeArteriaCerebralMedia"
                  required
                  @change="getACM(index)"
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaCerebralMedia
                  "
                  class="mx-2 flex-grow-0"
                  dense
                  validate-on-blur
                  v-mask="''"
                ></v-text-field>
                <span>- Percentil</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.percentil34"
                  required
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaCerebralMedia
                  "
                  validate-on-blur
                  v-mask="''"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-text-field>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaCerebralMedia
                  "
                  :items="['normal', 'alterado']"
                  v-model="laudo.indiceOpcao1"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-select>
                <span>para idade gestacional.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaCerebralMedia
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaCerebralMedia = true;
                    laudo.indicePulsatilidadeArteriaCerebralMedia = undefined;
                    laudo.indiceOpcao1 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaCerebralMedia = false;
                    laudo.indicePulsatilidadeArteriaCerebralMedia = undefined;
                    laudo.indiceOpcao1 = undefined;
                    laudo.percentil34 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeVelocidadePicoSistolico
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Velocidade de Pico Sistólica:</span>

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.opcaoVelocidade"
                  required
                  :disabled="
                    !camposExibidos[index].exibeVelocidadePicoSistolico
                  "
                  validate-on-blur
                  class="mx-2 flex-grow-0"
                  dense
                  v-mask="''"
                ></v-text-field>
                <span
                  required
                  :disabled="
                    !camposExibidos[index].exibeVelocidadePicoSistolico
                  "
                  validate-on-blur
                >
                  cm/s, correspondendo a
                </span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.opcaoVelocidade2"
                  required
                  dense
                  class="mx-2 flex-grow-0"
                  :disabled="
                    !camposExibidos[index].exibeVelocidadePicoSistolico
                  "
                  validate-on-blur
                  v-mask="''"
                ></v-text-field>
                <span> múltiplos da mediana, </span>

                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  dense
                  class="mx-2 flex-grow-0"
                  :disabled="
                    !camposExibidos[index].exibeVelocidadePicoSistolico
                  "
                  :items="[
                    'sem sinais de anemia',
                    'sugerindo anemia leve',
                    'sugerindo anemia moderada',
                    'sugerindo anemia grave',
                    'normal',
                  ]"
                  v-model="laudo.opcaoVelocidade3"
                  :clearable="podeEditar"
                ></v-select>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeVelocidadePicoSistolico"
                  @click="
                    camposExibidos[index].exibeVelocidadePicoSistolico = true;
                    laudo.velocidadePicoSistolico = undefined;
                    laudo.opcaoVelocidade = undefined;
                    laudo.opcaoVelocidade2 = undefined;
                    laudo.opcaoVelocidade3 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeVelocidadePicoSistolico = false;
                    laudo.velocidadePicoSistolico = undefined;
                    laudo.opcaoVelocidade = undefined;
                    laudo.opcaoVelocidade2 = undefined;
                    laudo.opcaoVelocidade3 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index]
                    .exibeIndicePulsatilidadeRelacaoCerebroPla
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Relação cérebro-placentária:</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.indicePulsatilidadeRelacaoCerebroPla"
                  required
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeRelacaoCerebroPla
                  "
                  validate-on-blur
                  class="mx-2 flex-grow-0"
                  dense
                  v-mask="''"
                ></v-text-field>
                <span>- Percentil</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.percentil20"
                  required
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeRelacaoCerebroPla
                  "
                  validate-on-blur
                  v-mask="''"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-text-field>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeRelacaoCerebroPla
                  "
                  validate-on-blur
                  :items="['normal', 'alterado']"
                  v-model.number="laudo.indiceOpcaoCerePlac"
                  :clearable="podeEditar"
                ></v-select>
                <span>para idade gestacional.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeRelacaoCerebroPla
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeRelacaoCerebroPla = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeRelacaoCerebroPla = false;
                    laudo.indicePulsatilidadeRelacaoCerebroPla = undefined;
                    laudo.percentil20 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h3>Artéria Uterina</h3>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeIndicePulsatilidadeArteriaUterinaD
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Índice de Pulsatilidade (direita):</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.indicePulsatilidadeArteriaUterinaD"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaD
                  "
                  validate-on-blur
                  v-mask="''"
                ></v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaD
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUterinaD = true;
                    laudo.indicePulsatilidadeArteriaUterinaD = undefined;
                    laudo.indiceOpcao2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUterinaD = false;
                    laudo.indicePulsatilidadeArteriaUterinaD = undefined;
                    laudo.indiceOpcao2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <!-- <v-col cols="12" class="d-flex justify-start align-center">
              <h3>Artéria Uterina esquerda:</h3>
            </v-col> -->

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeIndicePulsatilidadeArteriaUterinaE
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Índice de Pulsatilidade (esquerda):</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.indicePulsatilidadeArteriaUterinaE"
                  required
                  class="mx-2 flex-grow-0"
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaE
                  "
                  dense
                  validate-on-blur
                  v-mask="''"
                ></v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaE
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUterinaE = true;
                    laudo.indicePulsatilidadeArteriaUterinaE = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUterinaE = false;
                    laudo.indicePulsatilidadeArteriaUterinaE = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeIndicePulsatilidadeArteriaUterinaE2
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Índice de Pulsatilidade médio: </span>
                <span
                  cols="12"
                  :class="`pl-2 pr-2 d-flex justify-start align-center ${
                    camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaE2
                      ? ''
                      : 'text--disabled'
                  } `"
                >
                  {{ mediaIndice(index) }}
                </span>
                <span> - Percentil</span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaE2
                  "
                  class="mx-2 flex-grow-0"
                  dense
                  v-mask="''"
                  v-model="laudo.percentilArtMed"
                ></v-text-field>

                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaE2
                  "
                  validate-on-blur
                  :items="['normal', 'alterado']"
                  v-model="laudo.indiceOpcaoArtMed"
                  :clearable="podeEditar"
                ></v-select>
                <span>para idade gestacional.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeIndicePulsatilidadeArteriaUterinaE2
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUterinaE2 = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeIndicePulsatilidadeArteriaUterinaE2 = false;
                    laudo.percentilArtMed = undefined;
                    laudo.indiceOpcaoArtMed = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <!-- <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeDuctoVenoso ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeDuctoVenoso"
                  :items="[
                    'Ducto Venoso',
                    '----------------------------------',
                  ]"
                  v-model="laudo.ductoVenoso"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-select>

                <span v-if="laudo.ductoVenoso == 'Ducto Venoso'"
                  >Índice de Pulsatilidade:</span
                >

                <v-text-field
                  v-if="laudo.ductoVenoso == 'Ducto Venoso'"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.indicePulsatilidadeDuctoVenoso"
                  required
                  :disabled="!camposExibidos[index].exibeDuctoVenoso"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-mask="''"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-text-field>

                <v-select
                  v-if="laudo.ductoVenoso == 'Ducto Venoso'"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :disabled="!camposExibidos[index].exibeDuctoVenoso"
                  class="mx-2 flex-grow-0"
                  dense
                  validate-on-blur
                  :items="['normal', 'alterado']"
                  v-model.number="laudo.indiceOpcao4"
                  :clearable="podeEditar"
                ></v-select>
                <span v-if="laudo.ductoVenoso == 'Ducto Venoso'"
                  >para idade gestacional.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDuctoVenoso"
                  @click="
                    camposExibidos[index].exibeDuctoVenoso = true;
                    laudo.ductoVenoso = undefined;
                    laudo.indicePulsatilidadeDuctoVenoso = undefined;
                    laudo.indiceOpcao4 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeDuctoVenoso = false;
                    laudo.ductoVenoso = undefined;
                    laudo.indicePulsatilidadeDuctoVenoso = undefined;
                    laudo.indiceOpcao4 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  filled
                  color="#074535"
                  outlined
                  no-resize
                  dense
                  label="Comentários adicionais:"
                  v-model="laudo.comentariosAdcionaisMeio2"
                >
                </v-textarea>
              </v-col>

              <!-- <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${camposExibidos[index].
                  exibeFluxoUmbilicalOpcao1 ? '' : 'text--disabled'
                }`"
              >
                <span>Fluxo umbilical</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeFluxoUmbilicalOpcao1"
                  :items="['normal', 'com resistência alterado']"
                  v-model="laudo.fluxoUmbilicalOpcao1"
                  :clearable="podeEditar"
                ></v-select>
                <span>para a idade gestacional, com diástole</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  :disabled="!camposExibidos[index].exibeFluxoUmbilicalOpcao1"
                  dense
                  validate-on-blur
                  :items="['positiva', 'zero', 'reversa']"
                  v-model="laudo.fluxoUmbilicalOpcao2"
                  :clearable="podeEditar"
                ></v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeFluxoUmbilicalOpcao1"
                  @click="camposExibidos[index].
                    exibeFluxoUmbilicalOpcao1 = true;
                    laudo.fluxoUmbilicalOpcao1 = undefined;
                    laudo.fluxoUmbilicalOpcao2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].
                    exibeFluxoUmbilicalOpcao1 = false;
                    laudo.fluxoUmbilicalOpcao1 = undefined;
                    laudo.fluxoUmbilicalOpcao2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${camposExibidos[index].
                  exibeCentralizacaoFetal ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeCentralizacaoFetal"
                  :items="['Ausência', 'Presença']"
                  v-model="laudo.centralizacaoFetal"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                ></v-select>
                <span>de centralização fetal.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeCentralizacaoFetal"
                  @click="camposExibidos[index].
                    exibeCentralizacaoFetal = true;
                    laudo.centralizacaoFetal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].
                    exibeCentralizacaoFetal = false;
                    laudo.centralizacaoFetal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${camposExibidos[index].
                  exibeRelacaoCerebroPlacentaria ? '' : 'text--disabled'
                }`"
              >
                <span>Relação cérebro-placentária</span>

                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeRelacaoCerebroPlacentaria"
                  class="mx-2 flex-grow-0"
                  dense
                  :items="['normal', 'alterado']"
                  v-model.number="laudo.relacaoCerebroPlacentaria"
                  :clearable="podeEditar"
                ></v-select>
                <span>para idade gestacional.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeRelacaoCerebroPlacentaria"
                  @click="camposExibidos[index].
                    exibeRelacaoCerebroPlacentaria = true;
                    laudo.relacaoCerebroPlacentaria = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].
                    exibeRelacaoCerebroPlacentaria = false;
                    laudo.relacaoCerebroPlacentaria = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${camposExibidos[index].
                  exibeDuctoVenosoIndice ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeDuctoVenosoIndice"
                  class="mx-2 flex-grow-0"
                  dense
                  :items="[
                    'Ducto venoso com Índice de Pulsatilidade',
                    '----------------------------------',
                  ]"
                  v-model="laudo.ductoVenosoIndice"
                  :clearable="podeEditar"
                ></v-select>

                <v-select
                  v-if="
                    laudo.ductoVenosoIndice ==
                    'Ducto venoso com Índice de Pulsatilidade'
                  "
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeDuctoVenosoIndice"
                  class="mx-2 flex-grow-0"
                  dense
                  :items="['normal', 'alterado']"
                  v-model.number="laudo.indiceOpcao5"
                  :clearable="podeEditar"
                ></v-select>
                <span
                  v-if="
                    laudo.ductoVenosoIndice ==
                    'Ducto venoso com Índice de Pulsatilidade'
                  "
                  >para idade gestacional.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDuctoVenosoIndice"
                  @click="camposExibidos[index].
                    exibeDuctoVenosoIndice = true;
                    laudo.ductoVenosoIndice = undefined;
                    laudo.indiceOpcao5 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].
                    exibeDuctoVenosoIndice = false;
                    laudo.ductoVenosoIndice = undefined;
                    laudo.indiceOpcao5 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${camposExibidos[index].
                  exibeArteriasUterinas ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  :disabled="!camposExibidos[index].exibeArteriasUterinas"
                  dense
                  validate-on-blur
                  :items="[
                    'Artérias uterinas com média do Índice de Pulsatilidade',
                    '----------------------------------',
                  ]"
                  v-model="laudo.arteriasUterinas"
                  :clearable="podeEditar"
                ></v-select>

                <v-select
                  v-if="
                    laudo.arteriasUterinas ==
                    'Artérias uterinas com média do Índice de Pulsatilidade'
                  "
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  class="mx-2 flex-grow-0"
                  :disabled="!camposExibidos[index].exibeArteriasUterinas"
                  dense
                  :items="['normal', 'alterado']"
                  v-model="laudo.arteriasUterinas2"
                  :clearable="podeEditar"
                ></v-select>
                <span
                  v-if="
                    laudo.arteriasUterinas ==
                    'Artérias uterinas com média do Índice de Pulsatilidade'
                  "
                  >para idade gestacional.</span
                >
                <v-select
                  v-if="
                    laudo.arteriasUterinas ==
                    'Artérias uterinas com média do Índice de Pulsatilidade'
                  "
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeArteriasUterinas"
                  validate-on-blur
                  :items="['reduzindo', 'aumentando']"
                  v-model.number="laudo.arteriasUterinas3"
                  :clearable="podeEditar"
                ></v-select>
                <span
                  cols="12"
                  class="d-flex justify-start align-center"
                  v-if="
                    laudo.arteriasUterinas ==
                    'Artérias uterinas com média do Índice de Pulsatilidade'
                  "
                  :disabled="!camposExibidos[index].exibeArteriasUterinas"
                  >o risco de restrição de crescimento fetal e
                  pré-eclâmpsia.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeArteriasUterinas"
                  @click="camposExibidos[index].
                    exibeArteriasUterinas = true;
                    laudo.arteriasUterinas = undefined;
                    laudo.arteriasUterinas2 = undefined;
                    laudo.arteriasUterinas3 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].
                    exibeArteriasUterinas = false;
                    laudo.arteriasUterinas = undefined;
                    laudo.arteriasUterinas2 = undefined;
                    laudo.arteriasUterinas3 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${camposExibidos[index].
                  exibeVelocidadeAcm ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeVelocidadeAcm"
                  class="mx-2 flex-grow-0"
                  dense
                  :items="[
                    'Velocidade máxima da ACM normal para a idade gestacional',
                    'Velocidade máxima da ACM alterada para a idade gestacional',
                    ' ',
                  ]"
                  v-model="laudo.velocidadeAcm"
                  :clearable="podeEditar"
                ></v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeVelocidadeAcm"
                  @click="camposExibidos[index].
                    exibeVelocidadeAcm = true;
                    laudo.velocidadeAcm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].
                    exibeVelocidadeAcm = false;
                    laudo.velocidadeAcm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->
              <v-col cols="12" class="d-flex justify-start align-center">
                <h3>Conclusão</h3>
              </v-col>
              <div class="pt-5">
                <v-checkbox
                  v-model="laudo.checkFluxoUmb"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Fluxo umbilical normal para a idade gestacional."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkAusFet"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Ausência de centralização fetal."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkRelCerNormal"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Relação cerebro-placentária normal para a idade gestacional."
                  validate-on-blur
                ></v-checkbox>
                <v-checkbox
                  v-model="laudo.checkFluxoUmbRes"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Fluxo umbilical com resistência elevada."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkRelCerAcima"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkCentraFet"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Centralização fetal."
                  validate-on-blur
                ></v-checkbox>
                <div
                  class="d-flex justify-content-start align-items-center mt-n5"
                >
                  <v-checkbox
                    v-model="laudo.checkDucto"
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    label="Ducto venoso "
                    validate-on-blur
                  ></v-checkbox>
                  &nbsp;&nbsp;
                  <v-select
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    validate-on-blur
                    :disabled="!laudo.checkDucto"
                    class="d-flex justify-content-start align-self-center mx-2 flex-grow-0"
                    dense
                    :items="['normal', 'alterado']"
                    v-model.number="laudo.ductoVenNormAlt"
                    :clearable="podeEditar"
                  ></v-select>
                </div>

                <v-checkbox
                  v-model="laudo.checkIndiceAcima"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkIndiceDentro"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce."
                  validate-on-blur
                ></v-checkbox>
                <v-checkbox
                  v-model="laudo.checkPicoAnemLeve"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkPicoAnemMod"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada."
                  validate-on-blur
                ></v-checkbox>

                <v-checkbox
                  v-model="laudo.checkPicoGra"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="d-flex justify-content-start align-items-center mt-n1"
                  label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave."
                  validate-on-blur
                ></v-checkbox>
              </div>

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  filled
                  outlined
                  full-width
                  no-resize
                  label="Comentários adicionais"
                  v-model="laudo.comentariosAdicionais"
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h3>Ecocardiograma</h3>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeIdadeGestacional
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Idade gestacional:</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.idadeGestacional"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeIdadeGestacional"
                >
                </v-text-field>
                <span>semanas.</span>
                <v-col cols="12" md="3" sm="12">
                  <v-select
                    :items="['Compatível.', 'Incompatível.']"
                    required
                    clearable
                    validate-on-blur
                    v-model="laudo.compativelIncompativel"
                  >
                  </v-select>
                </v-col>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeIdadeGestacional"
                  @click="
                    camposExibidos[index].exibeIdadeGestacional = true;
                    laudo.idadeGestacional = undefined;
                    laudo.compativelIncompativel = undefined;
                  "
                >
                  <v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeIdadeGestacional = false;
                    laudo.idadeGestacional = undefined;
                    laudo.compativelIncompativel = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeIndicacao ? '' : 'text--disabled'
                }`"
              >
                <span>Indicação:</span>
                <v-select
                  :items="[
                    'rotina',
                    'malformação em exame anterior',
                    'TN alterada? Ducto venoso alterado',
                    '__________________________________',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeIndicacao"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.indicacao"
                >
                </v-select>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeIndicacao"
                  @click="
                    camposExibidos[index].exibeIndicacao = true;
                    laudo.indicacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeIndicacao = false;
                    laudo.indicacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeSitus ? '' : 'text--disabled'
                }`"
              >
                <span>Situs:</span>
                <v-select
                  :items="[
                    'solitus (normal)',
                    'inverus',
                    'ambiuguous(isomerismo direito)',
                    'ambiuguous (isomerismo esquerdo)',
                  ]"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeSitus"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.situs"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.situs2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeSitus"
                >
                </v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSitus"
                  @click="
                    camposExibidos[index].exibeSitus = true;
                    laudo.situs = undefined;
                    laudo.situs2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSitus = false;
                    laudo.situs = undefined;
                    laudo.situs2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibePosicaoCardiaca
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Posição cardíaca:</span>
                <v-select
                  :items="['levocardia', 'dextrocardia', 'mesocardia']"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibePosicaoCardiaca"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.posicaoCardiaca"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.posicaoCardiaca2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibePosicaoCardiaca"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibePosicaoCardiaca"
                  @click="
                    camposExibidos[index].exibePosicaoCardiaca = true;
                    laudo.posicaoCardiaca = undefined;
                    laudo.posicaoCardiaca2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibePosicaoCardiaca = false;
                    laudo.posicaoCardiaca = undefined;
                    laudo.posicaoCardiaca2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeEixoCardiaco
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Eixo cardíaco:</span>
                <v-select
                  :items="[
                    'ápice para o lado esquerdo (cerca de 45º - normal)',
                    'ápice para a direita',
                    'ápice mesoposicionada',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeEixoCardiaco"
                  v-model="laudo.eixoCardiaco"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.eixoCardiaco2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeEixoCardiaco"
                >
                </v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeEixoCardiaco"
                  @click="
                    camposExibidos[index].exibeEixoCardiaco = true;
                    laudo.eixoCardiaco = undefined;
                    laudo.eixoCardiaco2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeEixoCardiaco = false;
                    laudo.eixoCardiaco = undefined;
                    laudo.eixoCardiaco2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeAreaCardiaca
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Área cardíaca:</span>
                <v-select
                  :items="[
                    '1/3 da área do torax (normal)',
                    'aumentado com domínio de câmaras direitas',
                    'aumentado com domínio de câmaras esquerdas',
                    'Índice cardiotoráxico',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeAreaCardiaca"
                  validate-on-blur
                  v-model="laudo.areaCardiaca"
                >
                </v-select>

                <v-text-field
                  v-if="laudo.areaCardiaca == 'Índice cardiotoráxico'"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeAreaCardiaca"
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.areaCardiacaCm"
                ></v-text-field>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.areaCardiaca2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeAreaCardiaca"
                >
                </v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeAreaCardiaca"
                  @click="
                    camposExibidos[index].exibeAreaCardiaca = true;
                    laudo.areaCardiaca = undefined;
                    laudo.areaCardiaca2 = undefined;
                    laudo.areaCardiacaCm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeAreaCardiaca = false;
                    laudo.areaCardiaca = undefined;
                    laudo.areaCardiaca2 = undefined;
                    laudo.areaCardiacaCm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeJuncaoAtrioventricular
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Junção atrioventricular:</span>
                <v-select
                  :items="['concordante', 'discordante']"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeJuncaoAtrioventricular"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.juncaoAtrioventricular"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.juncaoAtrioventricular2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeJuncaoAtrioventricular"
                >
                </v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeJuncaoAtrioventricular"
                  @click="
                    camposExibidos[index].exibeJuncaoAtrioventricular = true;
                    laudo.juncaoAtrioventricular = undefined;
                    laudo.juncaoAtrioventricular2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeJuncaoAtrioventricular = false;
                    laudo.juncaoAtrioventricular = undefined;
                    laudo.juncaoAtrioventricular2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeVeiasPulmonares
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Veias pulmonares:</span>
                <v-select
                  :items="[
                    'drenando no atrio esquerdo',
                    'drenagem anômola',
                    '__________________',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeVeiasPulmonares"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.veiasPulmonares"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.veiasPulmonares2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeVeiasPulmonares"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeVeiasPulmonares"
                  @click="
                    camposExibidos[index].exibeVeiasPulmonares = true;
                    laudo.veiasPulmonares = undefined;
                    laudo.veiasPulmonares2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeVeiasPulmonares = false;
                    laudo.veiasPulmonares = undefined;
                    laudo.veiasPulmonares2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeVeiasSistemicas
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Veias sistêmicas:</span>
                <v-select
                  :items="[
                    'drenando no átrio direito',
                    '_________________________',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeVeiasSistemicas"
                  validate-on-blur
                  v-model="laudo.veiasSistemicas"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.veiasSistemicas2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeVeiasSistemicas"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeVeiasSistemicas"
                  @click="
                    camposExibidos[index].exibeVeiasSistemicas = true;
                    laudo.veiasSistemicas = undefined;
                    laudo.veiasSistemicas2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeVeiasSistemicas = false;
                    laudo.veiasSistemicas = undefined;
                    laudo.veiasSistemicas2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeForameOval ? '' : 'text--disabled'
                }`"
              >
                <span>Forame oval:</span>
                <v-select
                  :items="[
                    'patente com passagem de fluxo do átrio direito para o esquerdo',
                    'válvula abualada (aneurismática)',
                    'válvula retificada',
                    'fluxo invertido (esquerda/direita)',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeForameOval"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.forameOval"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.forameOval2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeForameOval"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeForameOval"
                  @click="
                    camposExibidos[index].exibeForameOval = true;
                    laudo.forameOval = undefined;
                    laudo.forameOval2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeForameOval = false;
                    laudo.forameOval = undefined;
                    laudo.forameOval2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeSeptoInterventricular
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Septo interventricular:</span>
                <v-select
                  :items="[
                    'aparentemente integro',
                    'comunicação interventricular medindo',
                    'tipo muscular trabecular',
                    'tipo muscular apical',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeSeptoInterventricular"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.septoInterventricular"
                >
                </v-select>

                <v-text-field
                  v-if="
                    laudo.septoInterventricular ==
                    'comunicação interventricular medindo'
                  "
                  required
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeSeptoInterventricular"
                  v-model="laudo.septoInterventricularCm"
                ></v-text-field>

                <span
                  v-if="
                    laudo.septoInterventricular ==
                    'comunicação interventricular medindo'
                  "
                  cols="12"
                  md="1"
                  sm="12"
                  >mm, tipo via de entrada
                </span>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.septoInterventricular2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeSeptoInterventricular"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSeptoInterventricular"
                  @click="
                    camposExibidos[index].exibeSeptoInterventricular = true;
                    laudo.septoInterventricular = undefined;
                    laudo.septoInterventricular2 = undefined;
                    laudo.septoInterventricularCm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSeptoInterventricular = false;
                    laudo.septoInterventricular = undefined;
                    laudo.septoInterventricularCm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index]
                    .exibeValvasAtrioventricularesAorticaPulmonar
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Valvas atrioventriculares, aórtica e pulmonar:</span>
                <v-select
                  :items="[
                    'a análise do fluxo por elas ao mapeamento de fluxo em cores é  normal, sem evidências de regurgitaçãoe e estenose',
                    'não foram observadas alteraçõe anatômicas e funcionais evidentes das valvas',
                    '_____________________________________________',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index]
                      .exibeValvasAtrioventricularesAorticaPulmonar
                  "
                  validate-on-blur
                  v-model="laudo.valvasAtrioventricularesAorticaPulmonar"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.valvasAtrioventricularesAorticaPulmonar2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index]
                      .exibeValvasAtrioventricularesAorticaPulmonar
                  "
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index]
                      .exibeValvasAtrioventricularesAorticaPulmonar
                  "
                  @click="
                    camposExibidos[
                      index
                    ].exibeValvasAtrioventricularesAorticaPulmonar = true;
                    laudo.valvasAtrioventricularesAorticaPulmonar = undefined;
                    laudo.valvasAtrioventricularesAorticaPulmonar2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibeValvasAtrioventricularesAorticaPulmonar = false;
                    laudo.valvasAtrioventricularesAorticaPulmonar = undefined;
                    laudo.valvasAtrioventricularesAorticaPulmonar2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeTroncoPulmonar
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Tronco pulmonar:</span>
                <v-select
                  :items="[
                    'originando-se do ventrículo direito, com as artérias pulmonares direita e esquerda de dimensões normais',
                    'originando-se do ventrículo esquerdo',
                    'com dimensões reduzidas',
                    'com dimensões aumentadas após a valva pulmonar',
                    '_____________________________________________',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeTroncoPulmonar"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.troncoPulmonar"
                >
                </v-select>

                <span
                  v-if="laudo.troncoPulmonar == 'com dimensões reduzidas'"
                  cols="12"
                  md="1"
                  sm="12"
                  >( Z-score=</span
                >
                <v-text-field
                  v-if="laudo.troncoPulmonar == 'com dimensões reduzidas'"
                  required
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeTroncoPulmonar"
                  v-model="laudo.troncoPulmonarCm"
                ></v-text-field>
                <span
                  v-if="laudo.troncoPulmonar == 'com dimensões reduzidas'"
                  cols="12"
                  md="1"
                  sm="12"
                >
                  )</span
                >
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.troncoPulmonar2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeTroncoPulmonar"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeTroncoPulmonar"
                  @click="
                    camposExibidos[index].exibeTroncoPulmonar = true;
                    laudo.troncoPulmonarCm = undefined;
                    laudo.troncoPulmonar = undefined;
                    laudo.troncoPulmonar2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeTroncoPulmonar = false;
                    laudo.troncoPulmonarCm = undefined;
                    laudo.troncoPulmonar = undefined;
                    laudo.troncoPulmonar2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeAorta ? '' : 'text--disabled'
                }`"
              >
                <span>Aorta:</span>
                <v-select
                  :items="[
                    'originando-se do ventrículo esquerdo, de dimensões normais',
                    'originando-se do ventrículo direito',
                    'com dimensões reduzidas',
                    'com dimensões aumentadas após a valva aórtica',
                    '---------------------------------------------',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeAorta"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.aorta"
                >
                </v-select>

                <span
                  v-if="laudo.aorta == 'com dimensões reduzidas'"
                  cols="12"
                  md="1"
                  sm="12"
                  >( Z-score=</span
                >
                <v-text-field
                  v-if="laudo.aorta == 'com dimensões reduzidas'"
                  required
                  v-mask="''"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeAorta"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.aortaCm"
                ></v-text-field>
                <span
                  v-if="laudo.aorta == 'com dimensões reduzidas'"
                  cols="12"
                  md="1"
                  sm="12"
                >
                  )</span
                >
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.aorta2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeAorta"
                >
                </v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeAorta"
                  @click="
                    camposExibidos[index].exibeAorta = true;
                    laudo.aorta = undefined;
                    laudo.aorta2 = undefined;
                    laudo.aortaCm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeAorta = false;
                    laudo.aorta = undefined;
                    laudo.aorta2 = undefined;
                    laudo.aortaCm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeArcoAortico ? '' : 'text--disabled'
                }`"
              >
                <span>Arco aórtico</span>
                <v-select
                  :items="[
                    'voltado para a esquerda',
                    'voltado para a direita ',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeArcoAortico"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.arcoAortico"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.arcoAortico2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeArcoAortico"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeArcoAortico"
                  @click="
                    camposExibidos[index].exibeArcoAortico = true;
                    laudo.arcoAortico = undefined;
                    laudo.arcoAortico2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeArcoAortico = false;
                    laudo.arcoAortico = undefined;
                    laudo.arcoAortico2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibePatente ? '' : 'text--disabled'
                }`"
              >
                <span
                  >Canal arterial: Patente e apresenta fluxo de características
                  normais direcionando do tronco pulmonar para a aorta
                  descendente</span
                ><span> (IP</span>

                <v-text-field
                  v-mask="''"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibePatente"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.patente"
                >
                </v-text-field>
                <span>).</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.patente2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeArcoAortico"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibePatente"
                  @click="
                    camposExibidos[index].exibePatente = true;
                    laudo.patente = undefined;
                    laudo.patente2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibePatente = false;
                    laudo.patente = undefined;
                    laudo.patente2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeMiocardio ? '' : 'text--disabled'
                }`"
              >
                <span>Miocárdio:</span>
                <v-select
                  :items="[
                    'de ambos os ventrículos tem espessura e contratilidade normais, evidenciado boa função sistólica',
                    '_____________________________________________',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeMiocardio"
                  validate-on-blur
                  v-model="laudo.miocardio"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.miocardio2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeMiocardio"
                >
                </v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeMiocardio"
                  @click="
                    camposExibidos[index].exibeMiocardio = true;
                    laudo.miocardio = undefined;
                    laudo.miocardio2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeMiocardio = false;
                    laudo.miocardio = undefined;
                    laudo.miocardio2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span>Conclusão</span>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeConclusao ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :items="[
                    'Ecocardiograma fetal dentro dos limites da normalidade para a idade gestacional',
                    '_____________________________________________',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeConclusao"
                  validate-on-blur
                  v-model="laudo.conclusao"
                >
                </v-select>
                <span>.</span>
                <v-text-field
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.conclusao2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeConclusao"
                >
                </v-text-field>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeConclusao"
                  @click="
                    camposExibidos[index].exibeConclusao = true;
                    laudo.conclusao = undefined;
                    laudo.conclusao2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeConclusao = false;
                    laudo.conclusao = undefined;
                    laudo.conclusao2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span>Observação:</span>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeObservacao ? '' : 'text--disabled'
                }`"
              >
                <v-select
                  :items="[
                    'É importante ressaltar que após o nascimento o coração sofre modificações com fechamento do forame oval e do canal arterial podendo apararecer alterações não observadas na vida fetal',
                  ]"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeObservacao"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.observacao"
                >
                </v-select>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeObservacao"
                  @click="
                    camposExibidos[index].exibeObservacao = true;
                    laudo.observacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeObservacao = false;
                    laudo.observacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <component
        :is="modal.modalComponent"
        v-bind="{ show: modal.active }"
        @close="closeModal"
      />
    </template>
    <v-col cols="12" class="d-flex justify-start align-center pt-4">
      <v-btn
        v-if="podeEditar"
        color="primary"
        :loading="carregamentoDoLaudo"
        @click="
          () => {
            laudoId ? editarLaudo() : salvarLaudo();
          }
        "
      >
        salvar laudo
      </v-btn>

      <v-btn
        color="primary"
        v-if="temLaudo && !podeEditar"
        @click="imprimirLaudo"
        class="ml-5"
      >
        Imprimir
      </v-btn>

      <v-btn
        color="primary"
        v-if="!podeEditar"
        class="ml-5"
        @click="podeEditar = true"
      >
        Editar
      </v-btn>

      <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        v-if="temLaudo"
        @click="adcionarConteudoEditorPersonalizado"
        class="ml-5"
      >
        {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
      </v-btn>
    </v-col>
  </section>
</template>

<script>
import moment from 'moment';
import { VueEditor } from 'vue2-editor';
import { mapActions, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';
import TNCalcs from '@/helpers/calculosTn';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      carregamentoDoLaudo: false,
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      gestacaoText: [
        '',
        'única',
        'gemelar',
        'trigemelar',
        'quadrigemelar',
        'quíntuplo',
      ],
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      checkAxilaDireita: false,
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: [
        {
          exInsercao2: true,
          exibeIndicePulsatilidadeArteriaUterinaM: true,
          exPlacenta2: true,
          exibeIndicePulsatilidadeArteriaUmbilical: true,
          exibeDiastole: true,
          exibeIndicePulsatilidadeRelacaoCerebroPla: true,
          exibeVelocidadePicoSistolico: true,
          exibeIndicePulsatilidadeArteriaUterinaD: true,
          exibeDuctoVenoso: true,

          exibePatente: true,
          exibeVeiasSistemicas: true,
          exibeForameOval: true,
          exibeObservacao: true,
          exibeSeptoInterventricular: true,
          exibeAreaCardiaca: true,
          exibeArcoAortico: true,
          checkAxilaEsquerda: false,
          exibeConclusao: true,
          exibeTroncoPulmonar: true,
          exibeAorta: true,
          exibeMiocardio: true,
          exibeValvasAtrioventricularesAorticaPulmonar: true,
          exibeEixoCardiaco: true,

          exIdadeGest: true,
          exIndicacao: true,
          exSitus: true,
          exPosCardc: true,
          exEixoCardc: true,
          exAreaCardc: true,
          exJuncaoAtrioVentri: true,
          exVeiasPulm: true,
          exVeiasSistemicas: true,
          exForameOval: true,
          exSepto: true,
          exValvas: true,
          exTronco: true,
          exAorta: true,
          exArcoAort: true,
          exPatente: true,
          exMiocardio: true,
          exibeIndicacao: true,
          exibeVeiasPulmonares: true,
          exibePosicaoCardiaca: true,
          exibeJuncaoAtrioventricular: true,
          exibeSitus: true,
          exConclusao: true,
          exibeIdadeGestacional: true,

          exGest: true,
          exPosFetal: true,
          exDiamBiparietal: true,
          exCircCranio: true,
          exCircAbdomen: true,
          exComprimentoFemoral: true,
          exPesoFetal: true,
          exBatimentoFeto: true,
          exMovCorporais: true,
          exTonus: true,
          exVolLiquidoAmni: true,
          exPlacenta: true,
          exAusentes: true,
          exIdadeGest: true,
          exIdadeGest2: true,
          exDataProvavelParto: true,
          exDataProvavelPartoPrimTrim: true,
          exGestacaoConclusao: true,
          exGestacaoConclusao3: true,
          exPercentil1: true,
          exibeSituacao: true,
          exibeSituacao2: true,
          exPercentil2: true,
          exPercentil3: true,
          exibeGestacao: true,
          exPercentil4: true,
          exibeIndicePulsatilidadeArteriaCerebralMedia: true,
          exibeIndicePulsatilidadeArteriaUterinaE: true,
          exibeIndicePulsatilidadeArteriaUterinaE2: true,
          exibeFluxoUmbilicalOpcao1: true,
          exibeCentralizacaoFetal: true,
          exibeRelacaoCerebroPlacentaria: true,
          exibeDuctoVenosoIndice: true,
          exibeArteriasUterinas: true,
          exibeVelocidadeAcm: true,
          exibeIndicacaoExame: true,

          exPercentil5: true,
        },
      ],

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,
      tipoGemeo: [
        {
          text: 'única',
          value: 1,
        },
        {
          text: 'gemelar',
          value: 2,
        },
        {
          text: 'trigemelar',
          value: 3,
        },
        {
          text: 'quadrigemelar',
          value: 4,
        },
        {
          text: 'quíntuplo',
          value: 5,
        },
      ],
    };
  },

  watch: {
    'infosCompartilhadas.gestacao'(value) {
      const quantidadeDeLaudos = this.laudos.length;
      if (value === quantidadeDeLaudos) return;

      const diff = value - quantidadeDeLaudos;

      const novaQuantidade = [...Array(diff > 0 ? diff : diff * -1).keys()];

      if (diff > 0) novaQuantidade.forEach(() => this.laudos.push({}));
      else novaQuantidade.forEach(() => this.laudos.pop());
    },
    'infosCompartilhadas.dataUltimaMestrucao'(dataUltimaMestrucao) {
      this.$store.commit('Laudo/SET_INFO_COMPARTILHADAS', {
        semanasDUM:
          TNCalcs.idadeGestacionalMenstrualSemanas(dataUltimaMestrucao),
        diasDUM: TNCalcs.idadeGestacionalMenstrualDias(dataUltimaMestrucao),
      });
    },
  },

  computed: {
    temLaudo() {
      this.laudoId = this.$store.getters['Laudo/laudoId'];
      return !!this.$store.getters['Laudo/laudoId'];
    },

    infosCompartilhadas() {
      return this.$store.state.Laudo.infosCompartilhadas;
    },
    laudos() {
      return this.$store.state.Laudo.laudos;
    },
    loading() {
      return this.$store.getters['Laudo/loading'];
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudos[0].folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },

    getPercentilFemur(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].comprimentoFemoral) {
        x = this.laudos[valIndice].comprimentoFemoral;
        x = x / 10;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }
      if (semanas >= 12 && semanas <= 42) {
        let valores = this.getTabelaFemur(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil4 = resultado;
      } else {
        this.laudos[valIndice].percentil4 = '';
      }

      this.$forceUpdate();
    },
    getTabelaFemur(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[12] = [0.5, 1.1];
      tabelaIndices[13] = [0.8, 1.4];
      tabelaIndices[14] = [1.1, 1.7];
      tabelaIndices[15] = [1.4, 2.0];
      tabelaIndices[16] = [1.7, 2.4];
      tabelaIndices[17] = [2.0, 2.7];
      tabelaIndices[18] = [2.3, 3.0];
      tabelaIndices[19] = [2.6, 3.3];
      tabelaIndices[20] = [2.8, 3.6];
      tabelaIndices[21] = [3.1, 3.9];
      tabelaIndices[22] = [3.4, 4.1];
      tabelaIndices[23] = [3.6, 4.4];
      tabelaIndices[24] = [3.9, 4.7];
      tabelaIndices[25] = [4.1, 5.0];
      tabelaIndices[26] = [4.4, 5.2];
      tabelaIndices[27] = [4.6, 5.5];
      tabelaIndices[28] = [4.8, 5.7];
      tabelaIndices[29] = [5.0, 6.0];
      tabelaIndices[30] = [5.2, 6.2];
      tabelaIndices[31] = [5.4, 6.4];
      tabelaIndices[32] = [5.6, 6.6];
      tabelaIndices[33] = [5.8, 6.8];
      tabelaIndices[34] = [6.0, 7.0];
      tabelaIndices[35] = [6.1, 7.2];
      tabelaIndices[36] = [6.3, 7.3];
      tabelaIndices[37] = [6.4, 7.5];
      tabelaIndices[38] = [6.6, 7.7];
      tabelaIndices[39] = [6.7, 7.8];
      tabelaIndices[40] = [6.8, 7.9];
      tabelaIndices[41] = [6.9, 8.0];
      tabelaIndices[42] = [7.0, 8.1];
      return tabelaIndices[valSemanas];
    },
    getPercentilPesoFetal(valIndice) {
      let x;
      let semanas;
      if (!!this.laudos[valIndice].pesoFetal) {
        x = this.laudos[valIndice].pesoFetal;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }

      if (semanas >= 10 && semanas <= 40) {
        let valores = this.getPF(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil5 = resultado;
      } else {
        this.laudos[valIndice].percentil15 = '';
      }

      this.$forceUpdate();
    },
    getPF(valSemanas) {
      let tabelaIndices = {};
      tabelaIndices[10] = [26, 44];
      tabelaIndices[11] = [34, 56];
      tabelaIndices[12] = [43, 73];
      tabelaIndices[13] = [55, 91];
      tabelaIndices[14] = [70, 116];
      tabelaIndices[15] = [88, 146];
      tabelaIndices[16] = [110, 183];
      tabelaIndices[17] = [136, 226];
      tabelaIndices[18] = [167, 279];
      tabelaIndices[19] = [205, 341];
      tabelaIndices[20] = [248, 414];
      tabelaIndices[21] = [299, 499];
      tabelaIndices[22] = [359, 598];
      tabelaIndices[23] = [426, 710];
      tabelaIndices[24] = [503, 838];
      tabelaIndices[25] = [589, 981];
      tabelaIndices[26] = [685, 1141];
      tabelaIndices[27] = [791, 1319];
      tabelaIndices[28] = [908, 1513];
      tabelaIndices[29] = [1034, 1754];
      tabelaIndices[30] = [1169, 1949];
      tabelaIndices[31] = [1313, 2189];
      tabelaIndices[32] = [1465, 2441];
      tabelaIndices[33] = [1622, 2703];
      tabelaIndices[34] = [1783, 2971];
      tabelaIndices[35] = [1946, 3244];
      tabelaIndices[36] = [2110, 3516];
      tabelaIndices[37] = [2271, 3785];
      tabelaIndices[38] = [2427, 4045];
      tabelaIndices[39] = [2576, 4294];
      tabelaIndices[40] = [2714, 4524];

      return tabelaIndices[valSemanas];
    },

    getPercentilCC(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].circunferenciaCraniana) {
        x = this.laudos[valIndice].circunferenciaCraniana;
        x = x / 10;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }
      if (semanas >= 12 && semanas <= 40) {
        let valores = this.getTabelaCC(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          0.5 + ((97.5 - 0.5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil2 = resultado;
      } else {
        this.laudos[valIndice].percentil2 = '';
      }

      this.$forceUpdate();
    },
    getTabelaCC(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[13] = [5.6, 10.2];
      tabelaIndices[14] = [7.1, 11.7];
      tabelaIndices[15] = [8.4, 13.0];
      tabelaIndices[16] = [9.8, 14.4];
      tabelaIndices[17] = [11.2, 15.8];
      tabelaIndices[18] = [12.5, 17.1];
      tabelaIndices[19] = [13.8, 18.4];
      tabelaIndices[20] = [15.1, 19.7];
      tabelaIndices[21] = [16.3, 20.9];
      tabelaIndices[22] = [17.5, 22.1];
      tabelaIndices[23] = [18.7, 23.3];
      tabelaIndices[24] = [19.8, 24.4];
      tabelaIndices[25] = [20.9, 25.5];
      tabelaIndices[26] = [22.0, 26.6];
      tabelaIndices[27] = [23.0, 27.6];
      tabelaIndices[28] = [24.0, 28.6];
      tabelaIndices[29] = [24.9, 29.5];
      tabelaIndices[30] = [25.8, 30.4];
      tabelaIndices[31] = [26.7, 31.3];
      tabelaIndices[32] = [27.5, 32.1];
      tabelaIndices[33] = [28.2, 32.8];
      tabelaIndices[34] = [28.9, 33.5];
      tabelaIndices[35] = [29.6, 34.2];
      tabelaIndices[36] = [30.2, 34.8];
      tabelaIndices[37] = [30.7, 35.3];
      tabelaIndices[38] = [31.2, 35.8];
      tabelaIndices[39] = [31.6, 36.2];
      tabelaIndices[40] = [32.0, 36.6];
      return tabelaIndices[valSemanas];
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudos[0].folhas) {
        this.conteudoEditorPersonalizado = this.laudos[0].folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }
      let todos = [];
      for (let i = 0; i < this.laudos.length; i++) {
        if (this.laudos[i].indicacaoExame) {
          todos.push(`Indicação do exame ${this.laudos[i].indicacaoExame}.\n`);
        }
        if (this.laudos[i].gestacao) {
          todos.push(
            `Gestação ${
              this.gestacaoText[this.infosCompartilhadas.gestacao]
            }.\n`
          );
        }
        if (this.laudos[i].situacao) {
          todos.push(`\n<strong>Posição fetal</strong>\n`);
        }
        if (this.laudos[i].situacao) {
          let resultadoSituacao = `Situação ${this.laudos[i].situacao}`;
          if (this.laudos[i].apresentacao)
            resultadoSituacao += `, apresentação ${this.laudos[i].apresentacao}`;
          if (this.laudos[i].dorso)
            resultadoSituacao += `, com dorso ${this.laudos[i].dorso}${
              this.laudos[i].dorso2 ? `, ${this.laudos[i].dorso2}` : ''
            }`;
          resultadoSituacao += '.\n';
          todos.push(resultadoSituacao);
        }
        if (this.laudos[i].situacao) {
          todos.push(`\n<strong>Biometria fetal</strong>`);
        }
        todos.push(`\n`);
        if (this.laudos[i].diametroBiparietal) {
          todos.push(
            `DBP : ${
              this.laudos[i].diametroBiparietal
            } mm  ${
              this.laudos[i].percentil1
                ? '(percentil ' + this.laudos[i].percentil1 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].circunferenciaCraniana) {
          todos.push(
            `CC: ${
              this.laudos[i].circunferenciaCraniana
            } mm  ${
              this.laudos[i].percentil2
                ? '(percentil ' + this.laudos[i].percentil2 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].circunferenciaAbdominal) {
          todos.push(
            `CA: ${
              this.laudos[i].circunferenciaAbdominal
            } mm  ${
              this.laudos[i].percentil3
                ? '(percentil ' + this.laudos[i].percentil3 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].comprimentoFemoral) {
          todos.push(
            `CF: ${this.laudos[i].comprimentoFemoral} mm  ${
              this.laudos[i].percentil4
                ? '(percentil ' + this.laudos[i].percentil4 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].pesoFetal) {
          let resultadoPesoFetal = `Peso fetal estimado de ${this.laudos[i].pesoFetal} gramas`;
          if (this.laudos[i].percentil5)
            resultadoPesoFetal += `, correspondendo ao percentil ${this.laudos[i].percentil5}`;
          resultadoPesoFetal += '.\n';
          todos.push(resultadoPesoFetal);
        }
        todos.push(`\n<strong>Vitalidade fetal</strong>`);
        todos.push(`\n`);
        if (this.laudos[i].batimentosCardiacosFetais) {
          let resultadoBatimentosCardiacos = `Batimentos cardíacos fetais ${this.laudos[i].batimentosCardiacosFetais}`;
          if (this.laudos[i].batimentosCardiacosFetais === 'presentes') {
            if (this.laudos[i].batimentosCardiacosFetais2)
              resultadoBatimentosCardiacos += `, ${this.laudos[i].batimentosCardiacosFetais2}`;

            if (
              this.laudos[i].batimentosCardiacosFetais2 ===
                'com ritmo cardíaco irregular' &&
              this.laudos[i].caracterizacaoArritimia
            )
              resultadoBatimentosCardiacos += `, ${this.laudos[i].caracterizacaoArritimia}`;
            if (this.laudos[i].frequenciabpm)
              resultadoBatimentosCardiacos += `, com frequência cardíaca de ${this.laudos[i].frequenciabpm} bpm`;
          }
          resultadoBatimentosCardiacos += '.\n';
          todos.push(resultadoBatimentosCardiacos);
        }
        if (this.laudos[i].movimentosCorporais) {
          todos.push(
            `Movimentos corporais ${this.laudos[i].movimentosCorporais}.\n`
          );
        }
        if (this.laudos[i].tonus) {
          todos.push(`Tônus ${this.laudos[i].tonus}.\n`);
        }
        if (this.laudos[i].volumeLíquidoAmniotico) {
          todos.push(
            `Volume de líquido amniótico ${
              this.laudos[i].volumeLíquidoAmniotico
            }${
              this.laudos[i].percentil6
                ? ' com maior bolsão medindo ' +
                  this.laudos[i].percentil6 +
                  ' cm'
                : ''
            } .\n`
          );
        }
        if (this.laudos[i].placenta) {
          let resultadoPlacenta = `Placenta ${this.laudos[i].placenta}`;
          if (this.laudos[i].insercao)
            resultadoPlacenta += `, de inserção ${this.laudos[i].insercao}`;
          if (this.laudos[i].grau)
            resultadoPlacenta += `, grau ${this.laudos[i].grau} segundo a classificação de Grannum`;
          resultadoPlacenta += '.\n';
          todos.push(resultadoPlacenta);
        }
        if (this.laudos[i].comentariosAdcionaisMeio1) {
          todos.push(`${this.laudos[i].comentariosAdcionaisMeio1}.\n`);
        }

        todos.push(`\n<h4>Conclusão</h4>`);
        todos.push(`\n`);
        if (this.laudos[i].batimentosCardiacosFetais == 'ausentes') {
          todos.push(`Óbito fetal.\n`);
          todos.push(
            `Idade gestacional de ${this.laudos[i].idadeGestacional} semanas.\n`
          );
          todos.push(
            ` ${
              this.laudos[i].comentariosAdcionaisFinal1
                ? this.laudos[i].comentariosAdcionaisFinal1
                : ''
            }\n`
          );
        }
        if (this.laudos[i].batimentosCardiacosFetais == 'presentes') {
          todos.push(
            `Gestação tópica ${
              this.gestacaoText[this.infosCompartilhadas.gestacao]
            }${
              this.infosCompartilhadas.gestacao == 2
                ? this.laudos[i].gestacaoTres +
                  ' ' +
                  this.laudos[i].gestacaoQuatro
                : ''
            } ${
              this.infosCompartilhadas.gestacao > 2
                ? this.laudos[i].gestacaoCinco
                : ''
            }\n`
          );
          todos.push(
            `Idade gestacional compatível com ${
              this.laudos[i].idadeGestacional2
                ? this.laudos[i].idadeGestacional2 > 1
                  ? this.laudos[i].idadeGestacional2 + ' semanas '
                  : this.laudos[i].idadeGestacional2 + ' semana '
                : ''
            }${
              this.laudos[i].semanas11
                ? this.laudos[i].semanas11 > 1
                  ? this.laudos[i].semanas11 + ' dias '
                  : this.laudos[i].semanas11 + ' dia '
                : ''
            }.\n`
          );
          if (this.laudos[i].dataProvavelParto)
            todos.push(
              `Data provável do parto pela biometria fetal atual (40 semanas): ${this.dataFormatada(
                this.laudos[i].dataProvavelParto
              )}.\n`
            );
          if (this.laudos[i].dataProvavelParto1Trimestre)
            todos.push(
              `Data provável do parto por ultrassonografia corrigida: ${this.dataFormatada(
                this.laudos[i].dataProvavelParto1Trimestre
              )}.\n`
            );
          if (this.laudos[i].gestacaoConclusao)
            todos.push(
              `${this.laudos[i].gestacaoConclusao}${
                this.laudos[i].gestacaoConclusao ==
                'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                  ? ', sugerindo ' + this.laudos[i].gestacaoConclusao2
                  : ''
              }.\n`
            );

          if (this.laudos[i].gestacaoConclusao3) {
            todos.push(`${this.laudos[i].gestacaoConclusao3}.\n`);
          }

          if (this.laudos[i].comentariosAdcionaisFinal2)
            todos.push(`${this.laudos[i].comentariosAdcionaisFinal2}\n`);
        }
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n<h3>Doppler</h3>\n`);
        todos.push(`\n<strong>Artéria Umbilical</strong>`);
        if (this.laudos[i].indicePulsatilidadeArteriaUmbilical)
          todos.push(
            `Índice de Pulsatilidade: ${
              this.laudos[i].indicePulsatilidadeArteriaUmbilical
            }${
              this.laudos[i].percentil18
                ? ' - Percentil ' + this.laudos[i].percentil18 + ''
                : ''
            }${
              this.laudos[i].indiceOpcao3
                ? ' ' + this.laudos[i].indiceOpcao3 + ' para idade gestacional'
                : ''
            }.\n`
          );
        if (this.laudos[i].diastole)
          todos.push(`Diástole ${this.laudos[i].diastole}\n`);
        todos.push(`\n<strong>Artéria cerebral média</strong>`);
        if (this.laudos[i].indicePulsatilidadeArteriaCerebralMedia)
          todos.push(
            `Índice de Pulsatilidade: ${
              this.laudos[i].indicePulsatilidadeArteriaCerebralMedia
            }${
              this.laudos[i].percentil34
                ? ' - Percentil ' + this.laudos[i].percentil34 + ''
                : ''
            }${
              this.laudos[i].indiceOpcao1
                ? ' ' + this.laudos[i].indiceOpcao1 + ' para idade gestacional'
                : ''
            }.\n`
          );

        if (this.laudos[i].opcaoVelocidade)
          todos.push(
            `Velocidade de Pico Sistólica: ${this.laudos[i].opcaoVelocidade}${
              this.laudos[i].opcaoVelocidade2
                ? ' cm/s, correspondendo a ' +
                  this.laudos[i].opcaoVelocidade2 +
                  ''
                : ''
            }${
              this.laudos[i].opcaoVelocidade3
                ? ' ' +
                  ' múltiplos da mediana,' +
                  this.laudos[i].opcaoVelocidade3
                : ''
            }.\n`
          );

        if (this.laudos[i].indicePulsatilidadeRelacaoCerebroPla)
          todos.push(
            `Relação cérebro-placentária: ${
              this.laudos[i].indicePulsatilidadeRelacaoCerebroPla
            }${
              this.laudos[i].percentil20
                ? ' - Percentil ' + this.laudos[i].percentil20 + ''
                : ''
            }${
              this.laudos[i].indiceOpcaoCerePlac
                ? ' ' +
                  this.laudos[i].indiceOpcaoCerePlac +
                  ' para idade gestacional'
                : ''
            }.\n`
          );

        if (this.laudos[i].indicePulsatilidadeArteriaUterinaD) {
          todos.push(`\n<strong>Artéria Uterina</strong>\n`);
        }

        if (this.laudos[i].indicePulsatilidadeArteriaUterinaD) {
          todos.push(
            `Índice de Pulsatilidade (direita): ${this.laudos[i].indicePulsatilidadeArteriaUterinaD}.\n`
          );
        }
        if (this.laudos[i].indicePulsatilidadeArteriaUterinaE) {
          todos.push(
            `Índice de Pulsatilidade (esquerda): ${this.laudos[i].indicePulsatilidadeArteriaUterinaE}.\n`
          );
        }

        if (this.mediaIndice(i))
          todos.push(
            `Índice de Pulsatilidade médio: ${this.mediaIndice(i)}${
              this.laudos[i].percentilArtMed
                ? ' - Percentil ' + this.laudos[i].percentilArtMed + ''
                : ''
            }${
              this.laudos[i].indiceOpcaoArtMed
                ? ' ' +
                  this.laudos[i].indiceOpcaoArtMed +
                  ' para idade gestacional'
                : ''
            }.\n`
          );

        // if (this.laudos[i].ductoVenoso) {
        //   todos.push(
        //     `Ducto Venoso Índice de Pulsatilidade: ${
        //       this.laudos[i].indicePulsatilidadeDuctoVenoso
        //     }${this.laudos[i].ductoVenoso == 'Ducto Venoso' ? ', ' : ''}${
        //       this.laudos[i].ductoVenoso == 'Ducto Venoso'
        //         ? '' + this.laudos[i].indiceOpcao4
        //         : ''
        //     }${
        //       this.laudos[i].ductoVenoso == 'Ducto Venoso'
        //         ? ' para idade gestacional' + ''
        //         : ''
        //     }.\n`
        //   );
        // }

        if (this.laudos[i].comentariosAdcionaisMeio2) {
          todos.push(`${this.laudos[i].comentariosAdcionaisMeio2}.\n`);
        }

        todos.push(`\n<h4>Conclusão</h4>\n`);

        if (this.laudos[i].checkFluxoUmb) {
          todos.push(`Fluxo umbilical normal para a idade gestacional.\n`);
        }
        if (this.laudos[i].checkAusFet) {
          todos.push(`Ausência de centralização fetal.\n`);
        }
        if (this.laudos[i].checkRelCerNormal) {
          todos.push(
            `Relação cerebro-placentária normal para a idade gestacional.\n`
          );
        }
        if (this.laudos[i].checkFluxoUmbRes) {
          todos.push(`Fluxo umbilical com resistência elevada.\n`);
        }
        if (this.laudos[i].checkRelCerAcima) {
          todos.push(
            `Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar.\n`
          );
        }
        if (this.laudos[i].checkCentraFet) {
          todos.push(`Centralização fetal.\n`);
        }
        if (this.laudos[i].checkDucto) {
          todos.push(`Ducto Venoso ${this.laudos[i].ductoVenNormAlt}\n`);
        }
        if (this.laudos[i].checkIndiceAcima) {
          todos.push(
            `Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce.\n`
          );
        }
        if (this.laudos[i].checkIndiceDentro) {
          todos.push(
            `Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce.\n`
          );
        }
        if (this.laudos[i].checkPicoAnemLeve) {
          todos.push(
            `Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve.\n`
          );
        }
        if (this.laudos[i].checkPicoAnemMod) {
          todos.push(
            `Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada.\n`
          );
        }
        if (this.laudos[i].checkPicoGra) {
          todos.push(
            `Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave.\n`
          );
        }
        if (this.laudos[i].comentariosAdicionais) {
          todos.push(`${this.laudos[i].comentariosAdicionais}\n`);
        }

        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);
        todos.push(`\n`);

        todos.push(`\n<h3>Ecocardiograma</h3>\n`);


        if (this.laudos[i].idadeGestacional) {
          todos.push(
            `Idade gestacional: ${this.laudos[i].idadeGestacional} semanas. ${
              this.laudos[i].compativelIncompativel
                ? this.laudos[i].compativelIncompativel
                : ''
            }\n`
          );
        }

        if (this.laudos[i].indicacao) {
          todos.push(`Indicação: ${this.laudos[i].indicacao}.\n`);
        }

        if (this.laudos[i].situs) {
          todos.push(
            `Situs: ${this.laudos[i].situs}. ${
              this.laudos[i].situs2 ? this.laudos[i].situs2 : ''
            }\n`
          );
        }

        if (this.laudos[i].posicaoCardiaca) {
          todos.push(
            `Posição cardíaca: ${this.laudos[i].posicaoCardiaca}. ${
              this.laudos[i].posicaoCardiaca2
                ? this.laudos[i].posicaoCardiaca2
                : ''
            }\n`
          );
        }
        if (this.laudos[i].eixoCardiaco) {
          todos.push(
            `Eixo cardíaco: ${this.laudos[i].eixoCardiaco}. ${
              this.laudos[i].eixoCardiaco2 ? this.laudos[i].eixoCardiaco2 : ''
            }\n`
          );
        }

        if (this.laudos[i].areaCardiaca) {
          let resultadoAreaCardiaca = `Área cardíaca: ${this.laudos[i].areaCardiaca}`;

          if (this.laudos[i].areaCardiaca === 'Índice cardiotoráxico') {
            if (this.laudos[i].areaCardiacaCm) {
              resultadoAreaCardiaca += ` ${this.laudos[i].areaCardiacaCm}`;
            }
          }

          if (this.laudos[i].areaCardiaca2) {
            resultadoAreaCardiaca += `. ${this.laudos[i].areaCardiaca2}`;
          }

          resultadoAreaCardiaca += '.\n';
          todos.push(resultadoAreaCardiaca);
        }

        if (this.laudos[i].juncaoAtrioventricular) {
          todos.push(
            `Junção atrioventricular: ${
              this.laudos[i].juncaoAtrioventricular
            }. ${
              this.laudos[i].juncaoAtrioventricular2
                ? this.laudos[i].juncaoAtrioventricular2
                : ''
            }\n`
          );
        }
        if (this.laudos[i].veiasPulmonares) {
          todos.push(
            `Veias pulmonares: ${this.laudos[i].veiasPulmonares}. ${
              this.laudos[i].veiasPulmonares2
                ? this.laudos[i].veiasPulmonares2
                : ''
            }\n`
          );
        }

        if (this.laudos[i].veiasSistemicas) {
          todos.push(
            `Veias sistêmicas: ${this.laudos[i].veiasSistemicas}. ${
              this.laudos[i].veiasSistemicas2
                ? this.laudos[i].veiasSistemicas2
                : ''
            }\n`
          );
        }
        if (this.laudos[i].forameOval) {
          todos.push(
            `Forame oval: ${this.laudos[i].forameOval}. ${
              this.laudos[i].forameOval2 ? this.laudos[i].forameOval2 : ''
            }\n`
          );
        }

        if (this.laudos[i].septoInterventricular) {
          let resultadoBatimentosCardiacos = `Septo interventricular: ${this.laudos[i].septoInterventricular}`;

          if (
            this.laudos[i].septoInterventricular ===
            'comunicação interventricular medindo'
          ) {
            if (this.laudos[i].septoInterventricularCm) {
              resultadoBatimentosCardiacos += `, ${this.laudos[i].septoInterventricularCm} mm, tipo via de entrada.`;
            }
          }

          if (this.laudos[i].septoInterventricular2) {
            resultadoBatimentosCardiacos += ` ${this.laudos[i].septoInterventricular2}`;
          }

          resultadoBatimentosCardiacos += '.\n';
          todos.push(resultadoBatimentosCardiacos);
        }

        if (this.laudos[i].valvasAtrioventricularesAorticaPulmonar) {
          todos.push(
            `Valvas atrioventriculares, aórtica e pulmonar: ${
              this.laudos[i].valvasAtrioventricularesAorticaPulmonar
            }. ${
              this.laudos[i].valvasAtrioventricularesAorticaPulmonar2
                ? this.laudos[i].valvasAtrioventricularesAorticaPulmonar2
                : ''
            }\n`
          );
        }

        if (this.laudos[i].troncoPulmonar) {
          let resultadoTroncoPulmonar = `Tronco pulmonar: ${this.laudos[i].troncoPulmonar}`;

          if (this.laudos[i].troncoPulmonar === 'com dimensões reduzidas') {
            if (this.laudos[i].troncoPulmonarCm) {
              resultadoTroncoPulmonar += ` ( Z-score= ${this.laudos[i].troncoPulmonarCm} ).`;
            }
          }

          if (this.laudos[i].troncoPulmonar2) {
            resultadoTroncoPulmonar += ` ${this.laudos[i].troncoPulmonar2}`;
          }

          resultadoTroncoPulmonar += '.\n';
          todos.push(resultadoTroncoPulmonar);
        }

        if (this.laudos[i].aorta) {
          let resultadoAorta = `Aorta: ${this.laudos[i].aorta}`;

          if (this.laudos[i].aorta === 'com dimensões reduzidas') {
            if (this.laudos[i].aortaCm) {
              resultadoAorta += ` ( Z-score= ${this.laudos[i].aortaCm} ).`;
            }
          }

          if (this.laudos[i].aorta2) {
            resultadoAorta += ` ${this.laudos[i].aorta2}`;
          }

          resultadoAorta += '.\n';
          todos.push(resultadoAorta);
        }

        if (this.laudos[i].arcoAortico) {
          todos.push(
            `Arco aórtico: ${this.laudos[i].arcoAortico}. ${
              this.laudos[i].arcoAortico2 ? this.laudos[i].arcoAortico2 : ''
            }\n`
          );
        }

        if (this.laudos[i].patente) {
          todos.push(
            `Canal arterial: Patente e apresenta fluxo de características normais direcionando do tronco pulmonar para a aorta descendente (IP ${
              this.laudos[i].patente
            }). ${this.laudos[i].patente2 ? this.laudos[i].patente2 : ''}\n`
          );
        }

        if (this.laudos[i].miocardio) {
          todos.push(
            `Miocárdio: ${this.laudos[i].miocardio}. ${
              this.laudos[i].miocardio2 ? this.laudos[i].miocardio2 : ''
            }\n`
          );
        }

        if (this.laudos[i].conclusao) {
          todos.push(`\n<h4>Conclusão</h4>\n`);
        }

        if (this.laudos[i].conclusao) {
          todos.push(
            `${this.laudos[i].conclusao}. ${
              this.laudos[i].conclusao2 ? this.laudos[i].conclusao2 : ''
            }\n`
          );
        }

        if (this.laudos[i].observacao) {
          todos.push(`\n<strong>Observação: </strong>\n`);
        }
        if (this.laudos[i].observacao) {
          todos.push(`${this.laudos[i].observacao}.\n`);
        }
      }

      this.conteudoEditorPersonalizado = [];

      while (todos.length > 0) {
        const teste = todos.splice(0, 30);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    juntaPersonalizadoAoLaudo() {
      this.laudos[0].folhas = this.conteudoEditorPersonalizado;
      this.laudos[0].titulo =
        'ULTRASSONOGRAFIA OBSTÉTRICA COM DOPPLER E ECOCARDIOGRAMA';
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
    mediaIndice(i) {
      if (
        !this.laudos ||
        !this.laudos[i] ||
        !this.laudos[i].indicePulsatilidadeArteriaUterinaD ||
        !this.laudos[i].indicePulsatilidadeArteriaUterinaE
      )
        return '';

      let media = (
        (parseFloat(this.laudos[i].indicePulsatilidadeArteriaUterinaD) +
          parseFloat(this.laudos[i].indicePulsatilidadeArteriaUterinaE)) /
        2.0
      ).toFixed(2);

      let semanas;
      if (!!this.laudos[i].idadeGestacional2) {
        semanas = parseInt(this.laudos[i].idadeGestacional2);
      } else {
        return media;
      }

      if (semanas >= 11 && semanas <= 41) {
        let valores = this.getIMP(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (media - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[i].percentilArtMed = resultado;
      } else {
        this.laudos[i].percentilArtMed = '';
      }

      return media;
    },
    getIMP(valSemanas) {
      let tabelaIndices = {};
      tabelaIndices[11] = [1.18, 2.7];
      tabelaIndices[12] = [1.11, 2.53];
      tabelaIndices[13] = [1.05, 2.38];
      tabelaIndices[14] = [0.99, 2.24];
      tabelaIndices[15] = [0.94, 2.11];
      tabelaIndices[16] = [0.89, 1.99];
      tabelaIndices[17] = [0.85, 1.88];
      tabelaIndices[18] = [0.81, 1.79];
      tabelaIndices[19] = [0.78, 1.7];
      tabelaIndices[20] = [0.74, 1.61];
      tabelaIndices[21] = [0.71, 1.54];
      tabelaIndices[22] = [0.69, 1.47];
      tabelaIndices[23] = [0.66, 1.41];
      tabelaIndices[24] = [0.64, 1.35];
      tabelaIndices[25] = [0.62, 1.3];
      tabelaIndices[26] = [0.6, 1.25];
      tabelaIndices[27] = [0.58, 1.21];
      tabelaIndices[28] = [0.56, 1.17];
      tabelaIndices[29] = [0.55, 1.13];
      tabelaIndices[30] = [0.54, 1.1];
      tabelaIndices[31] = [0.52, 1.06];
      tabelaIndices[32] = [0.51, 1.04];
      tabelaIndices[33] = [0.5, 1.01];
      tabelaIndices[34] = [0.5, 0.99];
      tabelaIndices[35] = [0.49, 0.97];
      tabelaIndices[36] = [0.48, 0.95];
      tabelaIndices[37] = [0.48, 0.94];
      tabelaIndices[38] = [0.47, 0.92];
      tabelaIndices[39] = [0.47, 0.91];
      tabelaIndices[40] = [0.47, 0.9];
      tabelaIndices[41] = [0.47, 0.89];

      return tabelaIndices[valSemanas];
    },

    forceReRender() {
      this.componentKey++;
    },
    ...mapActions('Laudo', ['getLaudos']),
    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit('Laudo/SET_LOADING', true);
            if (!this.temLaudo) {
              this.$store.commit(
                'Laudo/SET_CAMPOS_EXIBIDOS',
                this.camposExibidos
              );
              await this.$store.dispatch(
                'Laudo/postLaudos',
                this.$route.query.exame
              );
            }
            await this.$store.dispatch('Consulta/patchConsulta', {
              consultaID: this.$route.query.consulta,
              path: 'status',
              value: 'LAUDADO',
            });
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: async (store, exame, consulta) => {
                await store.dispatch('Laudo/postLaudos', exame);
                await store.dispatch('Consulta/patchConsulta', {
                  consultaID: consulta,
                  path: 'status',
                  value: 'LAUDADO',
                });
              },
              exame: this.$route.query.exame,
              consulta: this.$route.query.consulta,
              codigo: 'POST_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.$store.commit('Laudo/SET_LOADING', false);
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    getIndicePulsatilidade(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].indicePulsatilidadeArteriaUmbilical) {
        x = this.laudos[valIndice].indicePulsatilidadeArteriaUmbilical;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresIndice(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil18 = resultado;
      } else {
        this.laudos[valIndice].percentil18 = '';
      }

      this.$forceUpdate();
    },
    getPercentilADBM(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].circunferenciaAbdominal) {
        x = this.laudos[valIndice].circunferenciaAbdominal;
        x = x / 10;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }

      if (semanas >= 12 && semanas <= 40) {
        let valores = this.getTabelaCABDM(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil3 = resultado;
      } else {
        this.laudos[valIndice].percentil3 = '';
      }

      this.$forceUpdate();
    },
    getTabelaCABDM(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[12] = [2.4, 6.8];
      tabelaIndices[13] = [3.8, 8.2];
      tabelaIndices[14] = [5.1, 9.5];
      tabelaIndices[15] = [6.4, 10.8];
      tabelaIndices[16] = [7.7, 12.1];
      tabelaIndices[17] = [9.0, 13.4];
      tabelaIndices[18] = [10.3, 14.7];
      tabelaIndices[19] = [11.5, 15.9];
      tabelaIndices[20] = [12.8, 17.2];
      tabelaIndices[21] = [14.0, 18.4];
      tabelaIndices[22] = [15.2, 19.6];
      tabelaIndices[23] = [16.3, 20.7];
      tabelaIndices[24] = [17.5, 21.9];
      tabelaIndices[25] = [18.6, 23.0];
      tabelaIndices[26] = [19.7, 24.1];
      tabelaIndices[27] = [20.8, 25.2];
      tabelaIndices[28] = [21.8, 26.2];
      tabelaIndices[29] = [22.9, 27.3];
      tabelaIndices[30] = [23.9, 28.3];
      tabelaIndices[31] = [24.9, 29.3];
      tabelaIndices[32] = [25.9, 30.3];
      tabelaIndices[33] = [26.9, 31.3];
      tabelaIndices[34] = [27.8, 32.2];
      tabelaIndices[35] = [28.7, 33.1];
      tabelaIndices[36] = [29.6, 34.0];
      tabelaIndices[37] = [30.5, 34.9];
      tabelaIndices[38] = [31.4, 35.8];
      tabelaIndices[39] = [32.2, 36.6];
      tabelaIndices[40] = [33.1, 37.5];

      return tabelaIndices[valSemanas];
    },

    getACM(valIndice) {
      //verificar se o valor tá entre 20 e 42

      let x;
      let semanas;
      let yRelacao;
      if (!!this.laudos[valIndice].indicePulsatilidadeArteriaCerebralMedia) {
        x = this.laudos[valIndice].indicePulsatilidadeArteriaCerebralMedia;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }
      if (!!this.laudos[valIndice].indicePulsatilidadeArteriaUmbilical) {
        yRelacao = this.laudos[valIndice].indicePulsatilidadeArteriaUmbilical;

        let indiceRCP = x / yRelacao;
        this.laudos[valIndice].indicePulsatilidadeRelacaoCerebroPla =
          indiceRCP.toFixed(2);
        let valores1 = this.getRelacaoCP(semanas);
        let menorvalor1 = valores1[0];
        let maiorvalor1 = valores1[1];
        let percentilRCP =
          5 +
          ((95 - 5) * (indiceRCP - menorvalor1)) / (maiorvalor1 - menorvalor1);

        percentilRCP = percentilRCP.toFixed(0);
        percentilRCP = percentilRCP > 100 ? ' >100 ' : percentilRCP;
        percentilRCP = percentilRCP < 0 ? ' <1 ' : percentilRCP;

        this.laudos[valIndice].percentil20 = percentilRCP;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresACM(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil34 = resultado;
      } else {
        this.laudos[valIndice].percentil34 = '';
      }

      this.$forceUpdate();
    },
    getRelacaoCP(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [4.17, 0.9];
      tabelaIndices[21] = [4.35, 0.91];
      tabelaIndices[22] = [4.55, 0.92];
      tabelaIndices[23] = [4.76, 0.93];
      tabelaIndices[24] = [5.0, 0.93];
      tabelaIndices[25] = [5.0, 0.93];
      tabelaIndices[26] = [5.26, 0.94];
      tabelaIndices[27] = [5.56, 0.95];
      tabelaIndices[28] = [5.88, 0.96];
      tabelaIndices[29] = [6.25, 0.97];
      tabelaIndices[30] = [6.67, 0.98];
      tabelaIndices[31] = [6.67, 0.98];
      tabelaIndices[32] = [7.14, 0.99];
      tabelaIndices[33] = [7.69, 1.0];
      tabelaIndices[34] = [8.33, 1.01];
      tabelaIndices[35] = [9.09, 1.02];
      tabelaIndices[36] = [10.0, 1.03];
      tabelaIndices[37] = [10.0, 1.04];
      tabelaIndices[38] = [11.11, 1.04];
      tabelaIndices[39] = [12.5, 1.05];
      tabelaIndices[40] = [14.29, 1.06];
      tabelaIndices[41] = [16.67, 1.08];
      tabelaIndices[42] = [20.0, 1.09];
      return tabelaIndices[valSemanas];
    },
    getValoresIndice(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.04, 2.03];
      tabelaIndices[21] = [0.98, 1.96];
      tabelaIndices[22] = [0.92, 1.9];
      tabelaIndices[23] = [0.86, 1.85];
      tabelaIndices[24] = [0.81, 1.79];
      tabelaIndices[25] = [0.76, 1.74];
      tabelaIndices[26] = [0.71, 1.69];
      tabelaIndices[27] = [0.67, 1.65];
      tabelaIndices[28] = [0.63, 1.61];
      tabelaIndices[29] = [0.59, 1.57];
      tabelaIndices[30] = [0.56, 1.54];
      tabelaIndices[31] = [0.53, 1.51];
      tabelaIndices[32] = [0.5, 1.48];
      tabelaIndices[33] = [0.48, 1.46];
      tabelaIndices[34] = [0.46, 1.44];
      tabelaIndices[35] = [0.44, 1.43];
      tabelaIndices[36] = [0.43, 1.42];
      tabelaIndices[37] = [0.42, 1.41];
      tabelaIndices[38] = [0.42, 1.4];
      tabelaIndices[39] = [0.42, 1.4];
      tabelaIndices[40] = [0.42, 1.4];
      tabelaIndices[41] = [0.42, 1.41];
      tabelaIndices[42] = [0.43, 1.42];
      return tabelaIndices[valSemanas];
    },
    getValoresACM(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.36, 2.31];
      tabelaIndices[21] = [1.4, 2.34];
      tabelaIndices[22] = [1.44, 2.37];
      tabelaIndices[23] = [1.47, 2.4];
      tabelaIndices[24] = [1.49, 2.42];
      tabelaIndices[25] = [1.51, 2.44];
      tabelaIndices[26] = [1.52, 2.45];
      tabelaIndices[27] = [1.53, 2.45];
      tabelaIndices[28] = [1.53, 2.46];
      tabelaIndices[29] = [1.53, 2.45];
      tabelaIndices[30] = [1.52, 2.44];
      tabelaIndices[31] = [1.51, 2.43];
      tabelaIndices[32] = [1.49, 2.41];
      tabelaIndices[33] = [1.46, 2.39];
      tabelaIndices[34] = [1.43, 2.36];
      tabelaIndices[35] = [1.4, 2.32];
      tabelaIndices[36] = [1.36, 2.28];
      tabelaIndices[37] = [1.32, 2.24];
      tabelaIndices[38] = [1.27, 2.19];
      tabelaIndices[39] = [1.21, 2.14];
      tabelaIndices[40] = [1.15, 2.08];
      tabelaIndices[41] = [1.08, 2.01];
      tabelaIndices[42] = [1.01, 1.94];
      return tabelaIndices[valSemanas];
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit(
              'Laudo/SET_CAMPOS_EXIBIDOS',
              this.camposExibidos
            );
            await this.$store.dispatch('Laudo/pathLaudos');
            this.patchLaudoTn(this.laudoId, this.laudos);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoTnOffline.bind({}),
              laudoId: this.laudoId,
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.obstetricaComDopplerEEcocardiodoppler',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },

  async mounted() {
    this.$store.commit('Laudo/RESET_LAUDOS');

    if (!!this.$route.query?.exame) {
      this.$store.dispatch('Laudo/getLaudos', this.$route.query.exame);
    }
    this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.length > 0
    ) {
      this.camposExibidos = [];
      for (
        let i = 0;
        i < this.$store.getters['laudoSelecionado'].laudoJson.length;
        i++
      ) {
        this.camposExibidos.push(
          this.$store.getters['laudoSelecionado'].laudoJson[i].camposExibidos
        );
      }
    }

    for (let i = this.camposExibidos.length; i < 5; i++) {
      this.camposExibidos.push({
        exInsercao2: true,
        exPlacenta2: true,
        exibeIndicePulsatilidadeArteriaUmbilical: true,
        exibeDiastole: true,
        exibeIndicePulsatilidadeRelacaoCerebroPla: true,
        exibeVelocidadePicoSistolico: true,
        exibeIndicePulsatilidadeArteriaUterinaD: true,
        exibeIndicePulsatilidadeArteriaUterinaM: true,
        exibeDuctoVenoso: true,

        exibePatente: true,
        exibeVeiasSistemicas: true,
        exibeForameOval: true,
        exibeObservacao: true,
        exibeSeptoInterventricular: true,
        exibeAreaCardiaca: true,
        exibeArcoAortico: true,
        checkAxilaEsquerda: false,
        exibeConclusao: true,
        exibeTroncoPulmonar: true,
        exibeAorta: true,
        exibeMiocardio: true,
        exibeValvasAtrioventricularesAorticaPulmonar: true,
        exibeEixoCardiaco: true,

        exIdadeGest: true,
        exIndicacao: true,
        exSitus: true,
        exPosCardc: true,
        exEixoCardc: true,
        exAreaCardc: true,
        exJuncaoAtrioVentri: true,
        exVeiasPulm: true,
        exVeiasSistemicas: true,
        exForameOval: true,
        exSepto: true,
        exValvas: true,
        exTronco: true,
        exAorta: true,
        exArcoAort: true,
        exPatente: true,
        exMiocardio: true,
        exibeIndicacao: true,
        exibeVeiasPulmonares: true,
        exibePosicaoCardiaca: true,
        exibeJuncaoAtrioventricular: true,
        exibeSitus: true,
        exConclusao: true,
        exibeIdadeGestacional: true,

        exGest: true,
        exPosFetal: true,
        exDiamBiparietal: true,
        exCircCranio: true,
        exCircAbdomen: true,
        exComprimentoFemoral: true,
        exPesoFetal: true,
        exBatimentoFeto: true,
        exMovCorporais: true,
        exTonus: true,
        exVolLiquidoAmni: true,
        exPlacenta: true,
        exAusentes: true,
        exIdadeGest: true,
        exIdadeGest2: true,
        exDataProvavelParto: true,
        exDataProvavelPartoPrimTrim: true,
        exGestacaoConclusao: true,
        exGestacaoConclusao3: true,
        exPercentil1: true,
        exibeSituacao: true,
        exibeSituacao2: true,
        exPercentil2: true,
        exPercentil3: true,
        exibeGestacao: true,
        exPercentil4: true,
        exibeIndicePulsatilidadeArteriaCerebralMedia: true,
        exibeIndicePulsatilidadeArteriaUterinaE: true,
        exibeIndicePulsatilidadeArteriaUterinaE2: true,
        exibeFluxoUmbilicalOpcao1: true,
        exibeCentralizacaoFetal: true,
        exibeRelacaoCerebroPlacentaria: true,
        exibeDuctoVenosoIndice: true,
        exibeArteriasUterinas: true,
        exibeVelocidadeAcm: true,
        exibeIndicacaoExame: true,

        exPercentil5: true,
      });
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
